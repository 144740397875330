import { H2, Icon, Menu, MenuItem, Tab, Tabs } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Box, Flex } from 'grid-styled';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Scopes, User } from '../../auth';
import { Settings } from '../../settings';
import {
  Card,
  CopyToClipboard,
  DataList,
  DataListItem,
  DefaultCallout,
  Module,
  ModuleContent,
  ModuleHeader,
  Subhead,
} from '../../shared';
import AccountOwnerPanelContainer from '../containers/AccountOwnerPanelContainer';
import ClientConnectionsUsageContainer from '../containers/ClientConnectionsUsageContainer';
import ClientMAUContainer from '../containers/ClientMAUContainer';
import DataExportUsageContainer from '../containers/DataExportUsageContainer';
import ExperimentUsageContainer from '../containers/ExperimentUsageContainer';
import MembersPanelContainer from '../containers/MembersPanelContainer';
import ServerConnectionsUsageContainer from '../containers/ServerConnectionsUsageContainer';
import ServerMAUContainer from '../containers/ServerMAUContainer';
import {
  Account,
  AuditLogEntries,
  AuditLogEntryDetail,
  CustomRole,
  getAccountDisplayName,
  LegacySubscription,
  Member,
  MemberSessions,
  MetricType,
  OwnerPromotion,
  Plan,
  PlanType,
  Project,
  SavedAccount,
  ScheduledTrial,
  Subscription,
  SubscriptionUsage,
  TrialFeatures,
  UsageIdentifier,
} from '../model';
import { AccountSecurityPanel } from './AccountSecurityPanel';
import { AuditLog } from './AuditLog/AuditLog';
import { DebugPanel } from './Billing/DebugPanel';
import { CancelPlanButton } from './CancelPlanButton';
import { FeatureTrialForm, FeatureTrialFormProps } from './FeatureTrialForm';
import { LegacySubscriptionAccountDetails } from './LegacySubscriptionAccountDetails';
import { LinkHub } from './LinkHub';
import { PlanForm, PlanFormProps } from './PlanForm';
import { SubscriptionAccountDetails } from './SubscriptionAccountDetails';

import './AccountList.scss';
import GeneralAcctInfoPanel from './GeneralAcctInfoPanel';

type AccountDetailsProps = Readonly<{
  settings: Settings;
  scopes: Scopes;
  account: Account;
  legacySubscription: LegacySubscription;
  subscription: Subscription;
  isUpdatingSubscription: boolean;
  isUpdatingAccountOwner: boolean;
  memberSessions: MemberSessions[];
  customRoles: { [accountId: string]: CustomRole };
  projects: Project[];
  isFavorited: boolean;
  onChangeTrialEnd: (date: Date) => void;
  onChangeGraceEnd: (date: Date) => void;
  onChangeLegacyTrialEnd: (date: Date) => void;
  onChangeLegacyGraceEnd: (date: Date) => void;
  onChangeEnterpriseCampaignDates(date: Date): void;
  onChangeAccountOwner: (promotion: OwnerPromotion) => void;
  onSubscribe: (plan: Plan) => void;
  onCancelSubscription: (account: Account, endImmediately: boolean) => void;
  onUpdateStripeSubscriptionId: (stripeSubscriptionId: string) => void;
  onClearPendingSubscriptionUpdate: () => void;
  onGetEntryDetails: (account: Account, entryUrl: string) => void;
  onSetFeatureTrial: (flagKey: string) => void;
  onSetFeatureExpirationDate: (flagKey: string) => void;
  onSetMfaDisabled: (account: Account, member: Member) => void;
  setSsoDisabled: () => void;
  onGetEntries: (
    {
      account,
      nextUrl,
      query,
      spec,
      date,
    }: {
      account: Account;
      nextUrl?: string;
      query?: string;
      spec?: string;
      date?: ReadonlyArray<Date | null | number>;
    },
  ) => void;
  subscriptionUsage: SubscriptionUsage;
  user: User;
  auditLogEntries: AuditLogEntries;
  entryDetails: AuditLogEntryDetail;
  onAddFavoritedAccount: (favoritedAccount: SavedAccount) => void;
  onRemoveFavoritedAccount: (favoritedAccount: SavedAccount) => void;
  onGetFavoritedAccounts: () => void;
  scheduledTrials: { [id in TrialFeatures]: ScheduledTrial[] };
}>;

export class AccountDetails extends React.Component<AccountDetailsProps, any> {
  public state = {
    activeUsageGraph: UsageIdentifier.CLIENT_MAU,
    stripeSubscriptionId: '',
  };

  public render() {
    const {
      settings,
      scopes,
      account,
      legacySubscription,
      subscription,
      isUpdatingSubscription,
      isUpdatingAccountOwner,
      memberSessions,
      customRoles,
      projects,
      onChangeTrialEnd,
      onChangeGraceEnd,
      onChangeLegacyTrialEnd,
      onChangeLegacyGraceEnd,
      onChangeAccountOwner,
      onSubscribe,
      onCancelSubscription,
      onGetEntries,
      onGetEntryDetails,
      subscriptionUsage,
      user,
      auditLogEntries,
      entryDetails,
      onAddFavoritedAccount,
      onRemoveFavoritedAccount,
      isFavorited,
      onSetFeatureTrial,
      onSetFeatureExpirationDate,
      scheduledTrials,
      onSetMfaDisabled,
      setSsoDisabled,
      onChangeEnterpriseCampaignDates,
    } = this.props;

    const isLegacy: boolean = subscription.planType === PlanType.LEGACY;
    return (
      <>
        <Helmet>
          <title>{`Account ${getAccountDisplayName(account)}`}</title>
        </Helmet>
        <Subhead>
          {getAccountDisplayName(account)}
          {settings.recentlyViewedAccounts && (
            <Icon
              className="star"
              onClick={() =>
                isFavorited
                  ? onRemoveFavoritedAccount({ id: account._id, displayName: getAccountDisplayName(account) })
                  : onAddFavoritedAccount({ id: account._id, displayName: getAccountDisplayName(account) })
              }
              icon={isFavorited ? IconNames.STAR : IconNames.STAR_EMPTY}
              iconSize={25}
            />
          )}
        </Subhead>

        <Flex>
          <Box width={1 / 2} p={1} mb={2}>
            {isLegacy ? (
              <LegacySubscriptionAccountDetails
                legacySubscription={legacySubscription}
                accountRequireMfa={account.requireMfa}
                onChangeLegacyGraceEnd={onChangeLegacyGraceEnd}
                onChangeLegacyTrialEnd={onChangeLegacyTrialEnd}
                scopes={scopes}
              />
            ) : (
              <SubscriptionAccountDetails
                subscriptionUsage={subscriptionUsage._usage}
                legacySubscription={legacySubscription}
                subscription={subscription}
                accountRequireMfa={account.requireMfa}
                onChangeTrialEnd={onChangeTrialEnd}
                onChangeGraceEnd={onChangeGraceEnd}
                onChangeEnterpriseCampaignDates={onChangeEnterpriseCampaignDates}
                settings={settings}
                scopes={scopes}
              />
            )}
          </Box>
          <Box width={1 / 2} p={1} mb={2}>
            <LinkHub
              accountID={account._id}
              accountOrg={account.organization}
              user={user}
              settings={settings}
              scopes={scopes}
            />
          </Box>
        </Flex>
        <Tabs id={account._id} animate={false} renderActiveTabPanelOnly={true}>
          <Tab
            id="members"
            title="Members"
            panel={
              <MembersPanelContainer
                account={account}
                settings={settings}
                scopes={scopes}
                memberSessions={memberSessions}
                customRoles={customRoles}
                onSetMfaDisabled={onSetMfaDisabled}
              />
            }
          />
          {scopes.accountOwnership && (
            <Tab
              id="owner"
              title="Owner"
              panel={
                <AccountOwnerPanelContainer
                  account={account}
                  isUpdatingAccountOwner={isUpdatingAccountOwner}
                  onChangeAccountOwner={onChangeAccountOwner}
                />
              }
            />
          )}
          {scopes.auditLog && (
            <Tab
              id="auditLogEntries"
              title="Audit log"
              panel={this.getAuditLogPanel(
                account,
                auditLogEntries,
                entryDetails,
                projects,
                onGetEntryDetails,
                onGetEntries,
              )}
            />
          )}
          {settings.enableGeneralTab && (
            <Tab id="generalAccountInfo" title="General" panel={<GeneralAcctInfoPanel />} />
          )}
          <Tab
            id="billing"
            title="Billing"
            panel={this.getBillingPanel(
              {
                subscription,
                settings,
                scopes,
                isSubmitting: isUpdatingSubscription,
                onSubmit: onSubscribe,
              },
              onCancelSubscription,
              account,
            )}
          />
          {settings.enableSchedulingTrialEntitlements && (
            <Tab
              id="featureTrial"
              title="Feature Trial"
              panel={this.getFeatureTrialPanel({
                subscription,
                settings,
                onSetFeatureTrial,
                scheduledTrials,
                onSetFeatureExpirationDate,
                account,
              })}
            />
          )}

          {scopes.accountUsage && (
            <Tab id="usage" title="Usage" panel={this.renderUsageNavBar(account, subscriptionUsage)} />
          )}
          <Tab id="project" title="Projects & environments" panel={this.getProjectsPanel(projects)} />
          {scopes.securityTab && (
            <Tab
              id="security"
              title="Security"
              panel={<AccountSecurityPanel account={account} scopes={scopes} setSsoDisabled={setSsoDisabled} />}
            />
          )}
        </Tabs>
      </>
    );
  }

  private getBillingPanel = (
    props: PlanFormProps,
    onCancelSubscription: (account: Account, endImmediately: boolean) => void,
    account: Account,
  ) => (
    <Flex>
      <Box width={1}>
        <H2>Billing contact</H2>
        <Flex>
          <Box width={1 / 2}>
            <ModuleContent snug>
              <DataList>
                <BillingContactEntry label="Name" value={account.billingContact.name} />
                <BillingContactEntry label="Email" value={account.billingContact.email} />
                <BillingContactEntry label="Company" value={account.billingContact.company} />
                <BillingContactEntry label="Address 1" value={account.billingContact.address1} />
                <BillingContactEntry label="Address 2" value={account.billingContact.address2} />
              </DataList>
            </ModuleContent>
          </Box>
          <Box width="10px" />
          <Box width={1 / 2}>
            <ModuleContent snug>
              <DataList>
                <BillingContactEntry label="City" value={account.billingContact.city} />
                <BillingContactEntry label="State" value={account.billingContact.state} />
                <BillingContactEntry label="Postal Code" value={account.billingContact.postalCode} />
                <BillingContactEntry label="Country" value={account.billingContact.country} />
                <BillingContactEntry label="PO Number" value={account.billingContact.poNumber} />
              </DataList>
            </ModuleContent>
          </Box>
        </Flex>

        <Flex mt={3}>
          <Box width={1 / 2}>
            {
              <>
                <Flex>
                  <Box>
                    <H2>Manage plan</H2>
                  </Box>
                </Flex>
                {this.props.scopes.enterprisePlanCreator || this.props.scopes.switchTrialType ? (
                  <PlanForm {...props} />
                ) : (
                  <DefaultCallout>
                    You do not have access to the plan manager. If you believe this is in error, please request access
                    in the #vitalstatistix Slack channel.
                  </DefaultCallout>
                )}
                {this.props.scopes.enterprisePlanCreator && (
                  <CancelPlanButton
                    onCancelSubscription={(cancelAccount: Account, endImmediately: boolean) =>
                      onCancelSubscription(cancelAccount, endImmediately)
                    }
                    account={account}
                    cancelImmediatelyIsEnabled={this.props.settings.enableCancelImmediatelyFunctionality}
                  />
                )}
              </>
            }
          </Box>
        </Flex>

        <Flex mt={3}>
          <Box width={1 / 2}>
            {this.props.scopes.billingDebug && (
              <DebugPanel
                subscription={this.props.subscription}
                onUpdateStripeSubscriptionId={this.props.onUpdateStripeSubscriptionId}
                onClearPendingSubscriptionUpdate={this.props.onClearPendingSubscriptionUpdate}
              />
            )}
          </Box>
        </Flex>
      </Box>
    </Flex>
  );

  private getFeatureTrialPanel = (props: FeatureTrialFormProps) => (
    <Flex>
      <Box width={1 / 2}>
        {
          <>
            <Flex>
              <Box>
                <H2>Feature trial</H2>
              </Box>
            </Flex>
            <FeatureTrialForm {...props} />
          </>
        }
      </Box>
    </Flex>
  );

  private renderUsageNavBar = (account: Account, subscriptionUsage: SubscriptionUsage) => {
    return (
      <div className="AccountUsage">
        <Menu>
          <MenuItem
            shouldDismissPopover={false}
            text="Client MAUs"
            className={this.state.activeUsageGraph === UsageIdentifier.CLIENT_MAU ? 'bp3-active' : ''}
            onClick={() => this.handleUsageClick(UsageIdentifier.CLIENT_MAU)}
          />
          <MenuItem
            shouldDismissPopover={false}
            text="Client connections"
            className={this.state.activeUsageGraph === UsageIdentifier.CLIENT_CONNECTIONS ? 'bp3-active' : ''}
            onClick={() => this.handleUsageClick(UsageIdentifier.CLIENT_CONNECTIONS)}
          />
          {subscriptionUsage._usage[MetricType.EVENTS_RECEIVED] !== 0 && (
            <MenuItem
              shouldDismissPopover={false}
              text="Experiment usage"
              className={this.state.activeUsageGraph === UsageIdentifier.EXPERIMENTS ? 'bp3-active' : ''}
              onClick={() => this.handleUsageClick(UsageIdentifier.EXPERIMENTS)}
            />
          )}
          {subscriptionUsage._usage[MetricType.EVENTS_PUBLISHED] !== 0 && (
            <MenuItem
              shouldDismissPopover={false}
              text="Data export usage"
              className={this.state.activeUsageGraph === UsageIdentifier.DATA_EXPORT ? 'bp3-active' : ''}
              onClick={() => this.handleUsageClick(UsageIdentifier.DATA_EXPORT)}
            />
          )}
          <MenuItem
            shouldDismissPopover={false}
            text="Server connections"
            className={this.state.activeUsageGraph === UsageIdentifier.SERVER_CONNECTIONS ? 'bp3-active' : ''}
            onClick={() => this.handleUsageClick(UsageIdentifier.SERVER_CONNECTIONS)}
          />
          <MenuItem
            shouldDismissPopover={false}
            text="Server MAUs"
            className={this.state.activeUsageGraph === UsageIdentifier.SERVER_MAU ? 'bp3-active' : ''}
            onClick={() => this.handleUsageClick(UsageIdentifier.SERVER_MAU)}
          />
        </Menu>
        <div className="AccountUsage--graph">{this.renderUsageGraph(account)}</div>
      </div>
    );
  };

  private renderUsageGraph = (account: Account) => {
    const { subscription, settings } = this.props;
    switch (this.state.activeUsageGraph) {
      case UsageIdentifier.SERVER_CONNECTIONS:
        return this.getUsageCard(
          <ServerConnectionsUsageContainer
            account={account}
            limit={subscription._limits[MetricType.SERVER_CONNECTIONS]}
          />,
          'Server connections',
        );
      case UsageIdentifier.CLIENT_CONNECTIONS:
        return this.getUsageCard(<ClientConnectionsUsageContainer account={account} />, 'Client connections');
      case UsageIdentifier.SERVER_MAU:
        return this.getUsageCard(<ServerMAUContainer account={account} settings={settings} />, 'Server MAUs');
      case UsageIdentifier.CLIENT_MAU:
        return this.getUsageCard(
          <ClientMAUContainer
            account={account}
            limit={subscription._limits[MetricType.MONTHLY_ACTIVE_USERS]}
            settings={settings}
          />,
          'Client MAUs',
        );
      case UsageIdentifier.EXPERIMENTS:
        return this.getUsageCard(
          <ExperimentUsageContainer account={account} limit={subscription._limits[MetricType.EVENTS_RECEIVED]} />,
          'Experiment events',
        );
      case UsageIdentifier.DATA_EXPORT:
        return this.getUsageCard(
          <DataExportUsageContainer account={account} limit={subscription._limits[MetricType.EVENTS_PUBLISHED]} />,
          'Data export events',
        );
    }
  };

  private handleUsageClick = (type: UsageIdentifier) => {
    this.setState({
      activeUsageGraph: type,
    });
  };

  private getUsageCard = (children: any, title: string) => {
    return (
      <Card>
        <Flex alignItems="center" justifyContent="space-between">
          <Box width={1 / 2} p={1} mb={2}>
            <h2>{title}</h2>
          </Box>
        </Flex>
        {children}
      </Card>
    );
  };

  private getAuditLogPanel = (
    account: Account,
    auditLogEntries: AuditLogEntries,
    entryDetails: AuditLogEntryDetail,
    projects: Project[],
    onGetEntryDetails: (account: Account, entryUrl: string) => void,
    onGetEntries: (
      {
        account,
        nextUrl,
        query,
        spec,
        date,
      }: {
        account: Account;
        nextUrl?: string;
        query?: string;
        spec?: string;
        date?: ReadonlyArray<Date | null | number>;
      },
    ) => void,
  ) => {
    return (
      <div className="AuditLog">
        {auditLogEntries ? (
          <>
            <AuditLog
              account={account}
              auditLogEntries={auditLogEntries}
              entryDetails={entryDetails}
              projects={projects}
              onGetEntries={onGetEntries}
              onGetEntryDetails={onGetEntryDetails}
            />
          </>
        ) : (
          <DefaultCallout>There are no audit log entries.</DefaultCallout>
        )}
      </div>
    );
  };

  private getProjectsPanel = (projects: Project[]) => {
    return (
      <div>
        {projects.map(proj => (
          <Flex key={proj._id}>
            <Box width={1} p={1}>
              <Module>
                <ModuleHeader>
                  <span>
                    {proj.name}{' '}
                    <CopyToClipboard content={proj.key}>
                      <code>{proj.key}</code>
                    </CopyToClipboard>{' '}
                    <CopyToClipboard content={proj._id}>
                      <code>{proj._id}</code>
                    </CopyToClipboard>
                  </span>
                </ModuleHeader>
                <ModuleContent snug seamless>
                  <Flex flexWrap="wrap">
                    {proj.environments &&
                      proj.environments.map(env => (
                        <Box width={1 / 3} p={1} key={env._id}>
                          <Card>
                            <Flex flexDirection="column">
                              <Box pb={2}>
                                <Icon icon={IconNames.FULL_CIRCLE} color={`#${env.color}`} /> {env.name}{' '}
                                <CopyToClipboard content={env.key}>
                                  <code>{env.key}</code>
                                </CopyToClipboard>
                              </Box>
                              <Box pb={1}>
                                <div className="pt-text-muted">SDK key</div>{' '}
                                <CopyToClipboard content={env.apiKey}>
                                  <code>{env.apiKey}</code>
                                </CopyToClipboard>
                              </Box>
                              <Box pb={1}>
                                <div className="pt-text-muted">Mobile key</div>{' '}
                                <CopyToClipboard content={env.mobileKey}>
                                  <code>{env.mobileKey}</code>
                                </CopyToClipboard>
                              </Box>
                              <Box>
                                <div className="pt-text-muted">Environment ID</div>{' '}
                                <CopyToClipboard content={env._id}>
                                  <code>{env._id}</code>
                                </CopyToClipboard>
                              </Box>
                            </Flex>
                          </Card>
                        </Box>
                      ))}
                  </Flex>
                </ModuleContent>
              </Module>
            </Box>
          </Flex>
        ))}
      </div>
    );
  };
}

type BillingContactEntryProps = {
  label: string;
  value?: string;
};

const BillingContactEntry = (props: BillingContactEntryProps) => (
  <DataListItem>
    <Flex justify="space-between" align="center">
      <Box>{props.label}</Box>
      <Box>{props.value || 'N/A'}</Box>
    </Flex>
  </DataListItem>
);
