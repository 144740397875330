import { subDays } from 'date-fns';
import * as numbro from 'numbro';
import { Link } from '../gonfalon';
import * as gonfalon from '../gonfalon';

export const UNLIMITED = -1;
export const MAX_RECENTLY_VIEWED_ACCOUNTS = 20;
const MAX_PROJECT_LIMIT = 2;
const MAX_ENVIRONMENT_LIMIT = 4;

type AccountLinks = Readonly<{
  self: Link;
  subscription: Link;
  members: Link;
  projects: Link;
  customRoles: Link;
}>;

export type Account = Readonly<{
  _links: AccountLinks;
  _id: string;
  organization?: string;
  ownerEmail: string;
  tokens?: ReadonlyArray<string>;
  samlConfig?: SamlConfig;
  requireMfa: boolean;
  billingContact: BillingContact;
  // this is only used in search
  subscription?: AccountListingSubscription;
  isSupportGenAiEnabled: boolean;
}>;

export type BillingContact = {
  name?: string;
  email?: string;
  company?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
  poNumber?: string;
};

export type OwnerPromotion = Readonly<{
  requestedBy: string;
  promotedEmail: string;
  demotedRole: string;
}>;

export type AuditLogEntries = Readonly<{
  entries: AuditLogEntry[];
  items: AuditLogEntry[];
  _links: gonfalon.ILinks;
  isFetching: boolean;
}>;

export type AuditLogEntry = Readonly<{
  _id: string;
  date: number;
  accesses: AuditLogAccesses[];
  description: string;
  kind: string;
  member: AuditLogMember;
  name: string;
  parent?: AuditLogParent;
  title: string;
  titleVerb: string;
  token?: AuditLogToken;
  _links: gonfalon.ILinks;
}>;

export type AuditLogEntryDetail = Readonly<{
  isFetching?: boolean;
  _id: string;
  date: number;
  accesses: AuditLogAccesses[];
  description: string;
  comment?: string;
  kind: string;
  member: AuditLogMember;
  name: string;
  parent?: AuditLogParent;
  title: string;
  titleVerb: string;
  token?: AuditLogToken;
  delta?: AuditLogEntryDelta[];
  previousVersion?: object;
  currentVersion?: object;
}>;

export type AuditLogMember = Readonly<{
  email: string;
  firstName?: string;
  lastName?: string;
}>;

export type AuditLogParent = Readonly<{
  name: string;
  resource: string;
}>;

export type AuditLogToken = Readonly<{
  name: string;
  ending: string;
}>;

export type AuditLogAccesses = Readonly<{
  action: any;
  resource: string;
}>;

export type AuditLogEntryDelta = Readonly<{
  op: string;
  path: string;
  value: any;
}>;

export enum ResourceType {
  NO_RESOURCE_SELECTED = 'noResourceSelected',
  ACCT = 'acct',
  APPLICATION = 'application',
  CODE_REFERENCE_REPOSITORY = 'codeReferenceRepository',
  EXPERIMENT = 'experiment',
  INTEGRATION = 'integration',
  MEMBER = 'member',
  TOKEN = 'token',
  PROJ = 'proj',
  CONTEXT_KIND = 'contextKind',
  ENV = 'env',
  DESTINATION = 'destination',
  FLAG = 'flag',
  SEGMENT = 'segment',
  METRIC = 'metric',
  METRIC_GROUP = 'metricGroup',
  RELEASE_PIPELINE = 'releasePipeline',
  RELAY_PROXY_GROUP = 'relayProxyConfig',
  ROLE = 'role',
  SERVICE_TOKEN = 'serviceToken',
  TEAM = 'team',
  TEMPLATE = 'template',
  WEBHOOK = 'webhook',
}

export const ResourcesReadableNames: { [key in ResourceType]: string } = {
  noResourceSelected: 'no resource selected',
  acct: 'account',
  application: 'application',
  codeReferenceRepository: 'code reference repository',
  experiment: 'experiment',
  integration: 'integration',
  member: 'member',
  token: 'token',
  proj: 'project',
  contextKind: 'context kind',
  env: 'environment',
  destination: 'destination',
  flag: 'flag',
  segment: 'segment',
  metric: 'metric',
  metricGroup: 'metric group',
  releasePipeline: 'release pipeline',
  relayProxyConfig: 'relay proxy config',
  role: 'role',
  serviceToken: 'service token',
  team: 'team',
  template: 'template',
  webhook: 'webhook',
};

export const ResourcesWrittenExpressions: { [key in ResourceType]: string } = {
  noResourceSelected: '',
  acct: 'acct',
  application: 'application/*',
  codeReferenceRepository: 'code-reference-repository/*',
  experiment: 'proj/*:env/*:experiment/*',
  integration: 'integration/*',
  member: 'member/*',
  token: 'member/*:token/*',
  proj: 'proj/*',
  contextKind: 'proj/*:context-kind/*',
  env: 'proj/*:env/*',
  destination: 'proj/*:env/*:destination/*',
  flag: 'proj/*:env/*:flag/*',
  segment: 'proj/*:env/*:segment/*',
  metric: 'proj/*:metric/*',
  metricGroup: 'proj/*:metric-group/*',
  releasePipeline: 'proj/*:release-pipeline/*',
  relayProxyConfig: 'relay-proxy-config/*',
  role: 'role/*',
  serviceToken: 'service-token/*',
  team: 'team/*',
  template: 'template/*',
  webhook: 'webhook/*',
};

export type UsageFilters = Readonly<{
  to: number;
  from: number;
  sdk?: string;
  version?: string;
}>;

export type SDKOption = Readonly<{
  groupHeader?: boolean;
  isDivider?: boolean;
  name: string;
  sdk: string;
  value: string;
  version?: string;
  nested?: boolean;
}>;

export type SamlConfig = Readonly<{
  enabled: boolean;
  requireSso: boolean;
  ssoUrl: string;
}>;

export enum NoAccountsMsg {
  DEFAULT = 'default',
  FAVORITED = 'favorited',
  RECENTLY_VIEWED = 'recentlyViewed',
}

export type SavedAccount = Readonly<{
  id: string;
  displayName: string;
}>;

export enum MFAStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  RESET = 'reset',
}

export enum UsageIdentifier {
  SERVER_CONNECTIONS = 'serverConnections',
  CLIENT_CONNECTIONS = 'clientConnections',
  SERVER_MAU = 'serverMAU',
  CLIENT_MAU = 'clientMAU',
  EXPERIMENTS = 'experiments',
  DATA_EXPORT = 'dataExport',
}

export enum SDKType {
  SERVER = 'server',
  CLIENT = 'client',
}

export enum UsageOrigin {
  STREAMS = 'streams',
  PUBLISHED = 'published',
  MAU = 'mau',
  EVENTS = 'events',
}

export enum UsageTypes {
  SERVER = 'server',
  CLIENT = 'client',
  RECEIVED = 'received',
  MAU = 'bycategory',
  EXPERIMENTS = 'experiments',
  PUBLISHED = 'published',
}

export enum ChartTypes {
  BAR = 'bar',
  LINE = 'line',
  AREA = 'events',
}

export type MembersPaginationData = Readonly<{
  links: {
    self: { href: string };
    first: { href: string };
    last: { href: string };
    next: { href: string };
    prev: { href: string };
  };
  totalCount: number;
}>;

export type MemberResponse = Readonly<{
  items: Member[];
  totalCount: number;
  _links: {
    self: { href: string; type: string };
    [key: string]: { href: string; type: string };
  };
}>;

export type Member = Readonly<{
  _id: string;
  _pendingInvite: boolean;
  email: string;
  firstName?: string;
  lastName?: string;
  role: AccountRole;
  customRoles: ReadonlyArray<string>;
  mfa: MFAStatus;
  teams: ReadonlyArray<TeamsData>;
}>;

export type TeamsData = Readonly<{
  key: string;
  name: string;
  customRoleKeys: ReadonlyArray<string>;
}>;

export type MemberSessions = Readonly<{
  memberId: string;
  lastActiveDate: string;
  authKind: string;
}>;

export type Environment = Readonly<{
  _id: string;
  key: string;
  name: string;
  color: string;
  defaultTtl: number;
  apiKey: string;
  mobileKey: string;
  secureMode: boolean;
}>;

export type Project = Readonly<{
  _id: string;
  key: string;
  name: string;
  includeInSnippetByDefault: boolean;
  environments: ReadonlyArray<Environment>;
}>;

export type CustomRole = Readonly<{
  _id: string;
  name: string;
  key: string;
}>;

export type LegacyPlanLimits = Readonly<{
  multipleProjects: boolean;
  multipleEnvironments: boolean;
  abTesting: boolean;
  teams: boolean;
  customRoles: boolean;
  auditLog: boolean;
  mauLimit: number;
  enforceSeatLimits: boolean;
}>;

export type LegacyPlanUsage = Readonly<{
  mauCount: {
    total: number;
    anonymous: number;
    nonAnonymous: number;
    client: number;
    server: number;
  };
  projectCount: number;
  memberCount: number;
  environmentCount: number;
  abTesting: boolean;
}>;

export type LegacySubscription = Readonly<{
  _limits: LegacyPlanLimits;
  _usage: LegacyPlanUsage;
  trialStartDate: string;
  trialEndDate: string;
  graceStartDate?: string;
  graceEndDate?: string;
  periodStartDate?: string;
  periodEndDate?: string;
  seatCount?: number;
  isBeta: boolean;
  isTrial: boolean;
  isActive: boolean;
  isCurrent: boolean;
  isCanceled: boolean;
  name: string | null;
}>;

export enum AccountRole {
  ADMIN = 'admin',
  WRITER = 'writer',
  READER = 'reader',
  OWNER = 'owner',
}

export enum BillingInterval {
  ANNUAL_BILLING = 'AnnualBilling',
  MONTHLY_BILLING = 'MonthlyBilling',
}

export enum PlanType {
  STARTER = 'Starter',
  STANDARD_TRIAL = 'StandardTrial',
  PROFESSIONAL = 'Professional',
  ENTERPRISE_TRIAL = 'EnterpriseTrial',
  ENTERPRISE = 'Enterprise',
  LEGACY = 'Legacy',
  STANDARD_TRIAL_2021 = 'StandardTrial2021',
  STARTER_2021 = 'Starter2021',
  PROFESSIONAL_2021 = 'Professional2021',
  FOUNDATION_2023 = 'Foundation2023',
  ENTERPRISE_2023 = 'Enterprise2023',
  FOUNDATION_TRIAL_2023 = 'FoundationTrial2023',
  GUARDIAN_TRIAL_2024 = 'GuardianTrial2024',
  GUARDIAN_2024 = 'Guardian2024',
  GUARDIAN_SEATS_2024 = 'Guardian2024S',
}

export const isFixedPlan = (planType: PlanType) =>
  planType === PlanType.STARTER ||
  planType === PlanType.STANDARD_TRIAL ||
  planType === PlanType.ENTERPRISE_TRIAL ||
  planType === PlanType.STANDARD_TRIAL_2021 ||
  planType === PlanType.FOUNDATION_TRIAL_2023 ||
  planType === PlanType.GUARDIAN_TRIAL_2024;

export const isSelfServePlan = (planType: PlanType) =>
  planType === PlanType.STARTER ||
  planType === PlanType.PROFESSIONAL ||
  planType === PlanType.STARTER_2021 ||
  planType === PlanType.PROFESSIONAL_2021 ||
  planType === PlanType.FOUNDATION_2023;

export const isHostBasedPlan = (planType: PlanType) =>
  planType === PlanType.ENTERPRISE_2023 || planType === PlanType.FOUNDATION_2023 || planType === PlanType.GUARDIAN_2024;

export const isEnterprisePlan = (planType: PlanType) =>
  planType === PlanType.ENTERPRISE || planType === PlanType.ENTERPRISE_2023;

export const isGuardianPlan = (planType: PlanType) =>
  planType === PlanType.GUARDIAN_2024 || planType === PlanType.GUARDIAN_SEATS_2024;

export enum MetricType {
  SEATS = 'SeatCount',
  SERVER_CONNECTIONS = 'ServerConnections',
  MONTHLY_ACTIVE_USERS = 'MonthlyActiveUsers',
  EVENTS_RECEIVED = 'EventsReceived',
  EXPERIMENTATION_KEYS = 'ExperimentationKeys',
  EXPERIMENTATION_MAU = 'ExperimentationMAU',
  EVENTS_PUBLISHED = 'EventsPublished',
  SSO = 'SSO',
  HOSTS = 'Hosts',
}

export type SubscriptionUsage = Readonly<{
  _usage: AccountMetrics;
  _links: gonfalon.ILinks;
}>;

export type AccountMetrics = Readonly<{
  [MetricType.SEATS]: number;
  [MetricType.SERVER_CONNECTIONS]: number;
  [MetricType.MONTHLY_ACTIVE_USERS]: number;
  [MetricType.EVENTS_RECEIVED]: number;
  [MetricType.EXPERIMENTATION_KEYS]: number;
  [MetricType.EXPERIMENTATION_MAU]: number;
  [MetricType.EVENTS_PUBLISHED]: number;
  [MetricType.SSO]: number;
  [MetricType.HOSTS]: number;
  [key: string]: number;
}>;

export enum SubscriptionState {
  ACTIVE_SUBSCRIPTION = 'ActiveSubscription',
  ACTIVE_TRIAL = 'ActiveTrial',
  ACTIVE_TRIAL_WITH_SUBSCRIPTION = 'ActiveTrialWithSubscription',
  GRACE_PERIOD_TRIAL = 'GracePeriodTrial',
  LAPSED_TRIAL = 'LapsedTrial',
  FAILED_PAYMENT_SUBSCRIPTION = 'FailedPaymentSubscription',
  PENDING_CANCELED_SUBSCRIPTION = 'PendingCanceledSubscription',
  CANCELED_SUBSCRIPTION = 'CanceledSubscription',
  LAPSED_SUBSCRIPTION = 'LapsedSubscription',
}

export const hasSubscription = (subscription: Subscription) =>
  subscription.state === SubscriptionState.ACTIVE_SUBSCRIPTION ||
  subscription.state === SubscriptionState.ACTIVE_TRIAL_WITH_SUBSCRIPTION ||
  subscription.state === SubscriptionState.FAILED_PAYMENT_SUBSCRIPTION ||
  subscription.state === SubscriptionState.LAPSED_SUBSCRIPTION;

export const SubscriptionStateDescriptions: { [key in SubscriptionState]: string } = {
  ActiveTrial:
    'Subscription is in the trial period and can be canceled or not (if you cancel while on trial, that trial should continue until the end)',
  ActiveTrialWithSubscription: 'Subscription has a plan but is still in the trial period and is not canceled',
  GracePeriodTrial: 'Subscription is a trial but no longer is in the trial period and is not canceled',
  LapsedTrial: 'Subscription is a trial but is canceled or past the grace period',
  ActiveSubscription:
    'Subscription is a paid plan type, not canceled, not on a trial, and has an unenforced grace period.',
  FailedPaymentSubscription: 'Subscription is a paid plan type that is not canceled and is in an active grace period.',
  PendingCanceledSubscription: 'Subscription is canceled. Has an active trial or active grace period.',
  CanceledSubscription:
    'Subscription is a paid plan type, is not in the grace period, and is canceled. Has a lapsed grace period or if canceled an unenforced grace period.',
  LapsedSubscription:
    'Subscription is a paid plan type not in the trial period. Is not canceled but has a lapsed grace period.',
};

export const SubscriptionStateReadableNames: { [key in SubscriptionState]: string } = {
  ActiveTrial: 'Active trial',
  ActiveTrialWithSubscription: 'Active trial with subscription',
  GracePeriodTrial: 'Grace period trial',
  LapsedTrial: 'Lapsed trial',
  ActiveSubscription: 'Active subscription',
  FailedPaymentSubscription: 'Failed payment subscription',
  PendingCanceledSubscription: 'Pending canceled subscription',
  CanceledSubscription: 'Canceled subscription',
  LapsedSubscription: 'Lapsed subscription',
};

type ScheduledTrialOperations = Readonly<{
  action: string;
  op: string;
  value: string;
  variationIndex: number;
}>;

export type ScheduledTrial = Readonly<{
  accountId: string;
  environmentId: string;
  environmentKey: string;
  flagKey: string;
  operations: ScheduledTrialOperations[];
  projectId: string;
  projectKey: string;
  scheduledTime: number;
  _id: string;
}>;

export enum TrialFeatures {
  EXPERIMENTATION_KEY = 'account-limits-experiment-events',
  DATA_EXPORT_KEY = 'account-limits-dataexport-events',
}

export enum TrialFeaturesName {
  EXPERIMENTATION = 'Experimentation trial',
  DATA_EXPORT = 'Data export trial',
}

type CampaignType = {
  startDate: string;
  endDate: string;
  extensionCount: number;
};

export type Subscription = Readonly<{
  _limits: AccountMetrics;
  state: SubscriptionState;
  planType: PlanType;
  version: number;
  trialStartDate: string;
  trialEndDate: string;
  usagePeriodStartDate: string;
  usagePeriodEndDate: string;
  graceStartDate?: string;
  graceEndDate?: string;
  pendingUpdate?: boolean;
  stripeSubscriptionId?: string;
  trialExtensionCount: number;
  subscriptionType?: string;
  billingInterval?: string;
  campaigns: {
    CustomerEnterpriseCampaign: CampaignType;
  };
}>;

export type AccountListingSubscription = Readonly<{
  state: SubscriptionState;
  type: string;
}>;

export type Plan = Readonly<{
  planType: PlanType;
  free: boolean;
  limits: AccountMetrics;
  effectiveStartDate: number;
  prorationTime: number;
  billingInterval: BillingInterval;
  featureTrial: TrialFeatures;
}>;

export enum CatfoodProjectKeys {
  LAUNCH_DARKLY = 'launchdarkly',
  ACCOUNT_MAGIC = 'account-magic',
}

export enum CatfoodFlagKeys {
  ENFORCE_SEAT_LIMITS = 'enforce-seat-limits',
}

export type Promotion = Readonly<{
  requestedBy: string;
  promotedEmail: string;
  demotedRole: string;
}>;

export const getAccountDisplayName = (acct: Account) => acct.organization || acct.ownerEmail || acct._id;
export const hasName = (member: Member) => member.firstName && member.lastName;

export const getMemberDisplayName = (member: Member) => {
  if (member.firstName && member.lastName) {
    return `${member.firstName} ${member.lastName}`;
  } else {
    return member.email;
  }
};

export const isOwner = (member: Member) => member.role === AccountRole.OWNER;

export const getLegacyMAUPercentage = (sub: LegacySubscription) => sub._usage.mauCount.client / sub._limits.mauLimit;
export const getUsagePercentage = (subscriptionUsage: number, subLimit: number) => subscriptionUsage / subLimit;

export const getDefaultDateRangeForUsage = () => {
  const amonthago = subDays(new Date(), 30);
  const now = new Date();

  return {
    from: amonthago.valueOf(),
    to: now.valueOf(),
  };
};

export const getDefaultDateRangeForHighUsage = () => {
  const start = subDays(new Date(), 14);
  const now = new Date();

  return {
    from: start.valueOf(),
    to: now.valueOf(),
  };
};

export const computeLimitRatio = (usage: number, limit: number) => (limit === -1 ? Infinity : usage / limit);

export const getProjectLimit = (limits: LegacyPlanLimits) => {
  return limits.multipleProjects ? -1 : MAX_PROJECT_LIMIT;
};

export const getEnvironmentLimit = (limits: LegacyPlanLimits) => {
  return limits.multipleEnvironments ? -1 : MAX_ENVIRONMENT_LIMIT;
};

export const formatLimit = (limit: number) => (limit === -1 ? '∞' : limit);

export const formatLimitAsString = (limit: number | undefined) => {
  switch (limit) {
    case undefined:
    case null:
      return '0';
    case -1:
      return '∞';
    default:
      return numbro(limit).format({ thousandSeparated: true });
  }
};

export const getLegacySeatLimit = (sub: LegacySubscription) => {
  if ('seatCount' in sub) {
    return sub.seatCount;
  } else if (sub._limits.teams) {
    return UNLIMITED;
  } else {
    return 1;
  }
};

export const isPlanWithSSO = (planType: PlanType) =>
  planType === PlanType.STARTER_2021 || planType === PlanType.PROFESSIONAL_2021 || planType === PlanType.PROFESSIONAL;
