import * as React from 'react';
import { Time } from '../../shared/components';
import { AccountConfig, Platform } from '../model';
import { dateSuffix, formatNumber } from '../util';

type PlanReviewConfigProps = {
  title: React.ReactNode;
  config: AccountConfig;
  className: string;
  displayTrialDates: boolean;
};

export class PlanReviewConfig extends React.Component<PlanReviewConfigProps> {
  public render() {
    const { title, config, className, displayTrialDates } = this.props;
    const hideLimits = [Platform.StandardTrial, Platform.EnterpriseTrial, Platform.StandardTrial2021].includes(
      config.platform,
    );

    return (
      <>
        <tr className={className}>
          <td>{title}</td>
          <td />
          <td />
          <td />
          <td>
            {hideLimits &&
              displayTrialDates &&
              config.trialStartDate && (
                <>
                  <Time datetime={config.trialStartDate} format="L" utc />
                  {dateSuffix(config.trialStartDate)}
                </>
              )}
          </td>
          <td>
            {hideLimits &&
              displayTrialDates &&
              config.trialEndDate && (
                <>
                  <Time datetime={config.trialEndDate} format="L" utc />
                  {dateSuffix(config.trialEndDate)}
                </>
              )}
          </td>
          <td>{config.platform}</td>
          <td>{!hideLimits && formatNumber(config.seats)}</td>
          <td>{!hideLimits && formatNumber(config.hosts)}</td>
          <td>{!hideLimits && formatNumber(config.cMau)}</td>
          <td>{!hideLimits && formatNumber(config.experimentEvents)}</td>
          <td>{!hideLimits && formatNumber(config.experimentationKeys)}</td>
          <td>{!hideLimits && formatNumber(config.experimentationMau)}</td>
          <td>{!hideLimits && formatNumber(config.dataExportEvents)}</td>
          <td>{!hideLimits && formatNumber(config.sso)}</td>
        </tr>
      </>
    );
  }
}
