import { initialize as initializeLD, LDClient, LDFlagChangeset, LDUser } from 'launchdarkly-js-client-sdk';
import { isEqual, mapValues } from 'lodash';
import { Store } from 'redux';
import { getUser, User } from '../auth';
import config from '../config';
import { AppState } from '../reducer';
import { camelizeKeys } from '../shared/utils';
import { Actions } from './actions';
import { Settings } from './model';

type MaybeUser = User | null;
type UserSelector = (state: AppState) => MaybeUser;
type UserChangeHandler = (user: MaybeUser) => void;

export let client: LDClient | null = null;

// XXX: property names must be the camel-case version of the flag key, to make sure flag updates register properly
const evaluateFlags = (c: LDClient) => ({
  pricingCalculator: c.variation('pricing-calculator'),
  enableHighUsageChart: c.variation('enable-high-usage-charts'),
  enableOverages: c.variation('enable-overages'),
  recentlyViewedAccounts: c.variation('recently-viewed-accounts'),
  removeServerConnections: c.variation('remove-server-connections'),
  enableSchedulingTrialEntitlements: c.variation('enable-scheduling-trial-entitlements'),
  enableMemberSessions: c.variation('enable-member-sessions'),
  enableDetailedMauCharts: c.variation('enable-detailed-mau-usage'),
  dataExportPercentagePricing: c.variation('data-export-percentage-pricing'),
  enablePaginationOfMembers: c.variation('enable-pagination-of-members'),
  enableHoneycombLinks: c.variation('enable-honeycomb-links'),
  enableExtendingEnterpriseTrialCampaign: c.variation('enable-extending-enterprise-trial-campaign'),
  displayImmediatelyActionableProductsInAccountProvisionReview: c.variation(
    'display-immediately-actionable-products-in-account-provision-review',
  ),
  enableTeamsVisibility: c.variation('enable-teams-visibility', 'teams_off'),
  enableSubscriptionStateFiltering: c.variation('filter-accounts-by-subscription-state', false),
  enableCancelImmediatelyFunctionality: c.variation('enable-cancel-immediately-functionality', false),
  enableGeneralTab: c.variation('enable-general-tab', false),
});

const observeStore = (store: Store<AppState>, select: UserSelector, onChange: UserChangeHandler) => {
  let currentState: MaybeUser;

  const handleChange = () => {
    const nextState = select(store.getState());
    if (!isEqual(currentState, nextState)) {
      currentState = nextState;
      onChange(currentState);
    }
  };

  const unsubscribe = store.subscribe(handleChange);
  handleChange();
  return unsubscribe;
};

const userToLDUser: (user?: User) => LDUser = user =>
  user
    ? {
        key: user.user_id,
        email: user.email,
        custom: {
          groups: user.groups,
        },
      }
    : { anonymous: true };

export const initializeSettings = (store: Store<AppState>, user?: User) => {
  const ldUser = userToLDUser(user);
  const c = initializeLD(config.launchdarkly.clientId, ldUser, {
    baseUrl: config.launchdarkly.baseUrl,
    streamUrl: config.launchdarkly.streamUrl,
    eventsUrl: config.launchdarkly.eventsUrl,
    sendEventsOnlyForVariation: true,
  });

  c.on('change', (settings: LDFlagChangeset) => {
    store.dispatch(Actions.updateSettingsPublic(camelizeKeys(mapValues(settings, f => f.current)) as Settings));
  });

  observeStore(store, getUser, newUser => {
    if (!newUser) {
      return;
    }
    c.identify(userToLDUser(newUser));
    store.dispatch(Actions.updateSettingsPublic(evaluateFlags(c)));
  });

  store.dispatch(Actions.updateSettingsPrivate());

  client = c;
  return c;
};
