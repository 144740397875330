import { AnchorButton, HTMLTable, Icon, Position, Tooltip } from '@blueprintjs/core';
import * as React from 'react';
import { Settings } from '../../settings';
import { AccountConfig, AccountIdAndOrganization, LineItem, OrderSource, ProcessedPlan } from '../model';
import { ProcessedPlansByAccountIDState } from '../reducer';
import './AvailablePlans.scss';

type AvailablePlansProps = {
  date: string;
  processedPlansByAccountID: ProcessedPlansByAccountIDState;
  settings: Settings;
  fetchAvailablePlans: () => void;
  getSelectedPlanID: (accountID: string) => void;
};

export class AvailablePlans extends React.Component<AvailablePlansProps> {
  public render() {
    const [
      accountsWithNonLaunchOrdersAndNonZeroLimits,
      accountsWithNonLaunchOrdersAndZeroLimits,
      accountsWithOnlyLaunchOrdersAndNonZeroLimits,
      accountsWithOnlyLaunchOrdersAndZeroLimits,
    ] = this.splitAccountsByLaunchOrdersAndNewLimits();

    return (
      <>
        <div className={'tableFixHead'}>
          <HTMLTable condensed striped>
            <thead>
              <tr>
                <th>
                  Account ID
                  <AnchorButton minimal icon="refresh" className="refresh" onClick={this.props.fetchAvailablePlans} />
                </th>
              </tr>
            </thead>
            <tbody>
              {accountsWithNonLaunchOrdersAndNonZeroLimits.map(account => (
                <tr key={account.accountID}>
                  <td>
                    <a onClick={this.handleClick(account.accountID)}>
                      {account.accountID + ' - ' + account.organization}
                    </a>
                  </td>
                </tr>
              ))}
              {accountsWithNonLaunchOrdersAndZeroLimits.map(account => (
                <tr key={account.accountID}>
                  <td>
                    <a onClick={this.handleClick(account.accountID)}>
                      {account.accountID + ' - ' + account.organization}
                    </a>
                    <Tooltip content="Account plan sets new contracted limit to zero" position={Position.RIGHT}>
                      <Icon icon="delete" />
                    </Tooltip>
                  </td>
                </tr>
              ))}
              {accountsWithOnlyLaunchOrdersAndNonZeroLimits.map(account => (
                <tr key={account.accountID}>
                  <td>
                    <a onClick={this.handleClick(account.accountID)}>
                      {account.accountID + ' - ' + account.organization}
                    </a>
                    <Tooltip
                      content="Account plan consists of only its launch order and sets new contracted limit to non-zero value(s)"
                      position={Position.RIGHT}
                    >
                      <Icon icon="symbol-square" />
                    </Tooltip>
                  </td>
                </tr>
              ))}
              {accountsWithOnlyLaunchOrdersAndZeroLimits.map(account => (
                <tr key={account.accountID}>
                  <td>
                    <a onClick={this.handleClick(account.accountID)}>
                      {account.accountID + ' - ' + account.organization}
                    </a>
                    <Tooltip
                      content="Account plan consists of only its launch orders and sets new contracted limits to zero"
                      position={Position.RIGHT}
                    >
                      <Icon icon="symbol-circle" />
                    </Tooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </HTMLTable>
        </div>
      </>
    );
  }

  // Sort plans by most recent action required (newest to oldest)
  // Split accounts as follows:
  //  - Contains Non Launch Orders w/ Non-Zero Limits
  //  - Contains Non Launch Orders w/ Zero Limits
  //  - Contains Only Launch Orders w/ Eventual Non-Zero Limits
  //  - Contains Only Launch Orders w/ Eventual Zero Limits
  private splitAccountsByLaunchOrdersAndNewLimits(): [
    AccountIdAndOrganization[],
    AccountIdAndOrganization[],
    AccountIdAndOrganization[],
    AccountIdAndOrganization[]
  ] {
    const accountsWithNonLaunchOrdersAndNonZeroLimits: AccountIdAndOrganization[] = [];
    const accountsWithNonLaunchOrdersAndZeroLimits: AccountIdAndOrganization[] = [];
    const accountsWithOnlyLaunchOrdersAndNonZeroLimits: AccountIdAndOrganization[] = [];
    const accountsWithOnlyLaunchOrdersAndZeroLimits: AccountIdAndOrganization[] = [];

    const sortedPlans: string[] = this.sortPlans(this.props.processedPlansByAccountID);
    sortedPlans.forEach(accountID => {
      const {
        organization,
        endingLineItems,
        startingLineItems,
        washLineItems,
        plannedConfig,
      } = this.props.processedPlansByAccountID[accountID];

      const lineItems: LineItem[] = [...endingLineItems, ...startingLineItems, ...washLineItems];
      const launchItem: LineItem | undefined = lineItems.find(({ orderSource }) => orderSource === OrderSource.Launch);

      if (lineItems.some(({ orderSource }) => orderSource !== OrderSource.Launch)) {
        if (this.hasZeroLimits(plannedConfig)) {
          accountsWithNonLaunchOrdersAndZeroLimits.push({ accountID, organization });
        } else {
          accountsWithNonLaunchOrdersAndNonZeroLimits.push({ accountID, organization });
        }
      } else if (launchItem && this.hasZeroLimits(plannedConfig)) {
        accountsWithOnlyLaunchOrdersAndZeroLimits.push({ accountID, organization });
      } else {
        accountsWithOnlyLaunchOrdersAndNonZeroLimits.push({ accountID, organization });
      }
    });

    return [
      accountsWithNonLaunchOrdersAndNonZeroLimits,
      accountsWithNonLaunchOrdersAndZeroLimits,
      accountsWithOnlyLaunchOrdersAndNonZeroLimits,
      accountsWithOnlyLaunchOrdersAndZeroLimits,
    ];
  }

  // sortPlans sorts the list of plans from 'newest action required' to 'oldest action required'. This 'date of action' is determined for each plan by taking the most recent date of the following two:
  // the most recent start date of the starting line items, or the most recent end date of the ending line items.
  private sortPlans(processedPlans: ProcessedPlansByAccountIDState): string[] {
    return Object.keys(processedPlans).sort((a: string, b: string) => {
      const planA = processedPlans[a];
      const planB = processedPlans[b];

      const planAMostRecentActionableDate: number = this.findMostRecentActionDate(planA);
      const planBMostRecentActionableDate: number = this.findMostRecentActionDate(planB);

      if (planAMostRecentActionableDate > planBMostRecentActionableDate) {
        return -1;
      }
      if (planBMostRecentActionableDate > planAMostRecentActionableDate) {
        return 1;
      }
      return 0;
    });
  }

  // findMostRecentActionDate takes all the start dates of startingLineItems, all the end dates of endingLineItems, all the end dates of washLineItems and finds the most recent one.
  private findMostRecentActionDate(plan: ProcessedPlan): number {
    const dates: string[] = [];
    plan.startingLineItems.forEach(lineItem => {
      if (lineItem.startDate) {
        dates.push(lineItem.startDate);
      }
    });
    plan.endingLineItems.forEach(lineItem => {
      if (lineItem.endDate) {
        dates.push(lineItem.endDate);
      }
    });
    plan.washLineItems.forEach(lineItem => {
      if (lineItem.endDate) {
        dates.push(lineItem.endDate);
      }
    });

    const mostRecentDate: string = dates.reduce((previousDate, currentDate) => {
      return Date.parse(previousDate) >= Date.parse(currentDate) ? previousDate : currentDate;
    }, '');

    return Date.parse(mostRecentDate);
  }

  private hasZeroLimits(accountConfig: AccountConfig): boolean {
    const {
      seats,
      hosts,
      cMau,
      experimentEvents,
      experimentationKeys,
      experimentationMau,
      dataExportEvents,
    } = accountConfig;
    return (
      !seats && !hosts && !cMau && !experimentEvents && !experimentationKeys && !experimentationMau && !dataExportEvents
    );
  }

  private handleClick = (accountID: string) => {
    return (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      this.props.getSelectedPlanID(accountID);
    };
  };
}
