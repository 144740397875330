import { combineEpics } from 'redux-observable';
import * as accounts from './accounts';
import * as auth from './auth';
import * as notifications from './notifications';
import * as provisioning from './provisioning';
import * as settings from './settings';

export default combineEpics(
  notifications.processNotifications,
  notifications.postToSlack,
  accounts.accountsFetch,
  accounts.accountFetch,
  accounts.accountLegacySubscriptionFetch,
  accounts.accountSubscriptionFetch,
  accounts.accountSubscriptionUsageFetch,
  accounts.updateAccountOwner,
  accounts.subscriptionTrialEndUpdates,
  accounts.subscriptionGraceEndUpdates,
  accounts.enterpriseTrialCampaignUpdates,
  accounts.legacySubscriptionTrialEndUpdates,
  accounts.legacySubscriptionGraceEndUpdates,
  accounts.subscribe,
  accounts.cancelSubscription,
  accounts.clearPendingSubscriptionUpdate,
  accounts.updateStripeSubscriptionId,
  accounts.accountMembersFetch,
  accounts.accountMembersForExportFetch,
  accounts.accountMemberSessionsFetch,
  accounts.accountProjectsFetch,
  accounts.accountCustomRolesFetch,
  accounts.accountSearch,
  accounts.accountSearchClearance,
  accounts.accountUsageFetch,
  accounts.accountHighUsageFetch,
  accounts.sdkVersionsFetch,
  accounts.accountAuditLogEntriesFetch,
  accounts.accountAuditLogEntryDetailsFetch,
  settings.trackAccountSearch,
  settings.trackAccountSearchTiming,
  settings.settingsPrivateFetch,
  accounts.setFeatureTrial,
  accounts.setFeatureExpirationDate,
  accounts.getScheduledTrials,
  accounts.disableMfa,
  accounts.disableSsoSet,
  provisioning.availablePlansFetch,
  provisioning.selectPlanGet,
  provisioning.selectedPlanRefresh,
  provisioning.launchOrdersFetch,
  provisioning.launchOrderEndDateSet,
  provisioning.executedPlanSet,
  provisioning.rejectedPlanSet,
  auth.checkingAuth,
  auth.signIn,
  auth.signOut,
  accounts.accountMembersSearchEpic,
);
