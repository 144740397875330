import { Box, Flex } from 'grid-styled';
import * as React from 'react';
import { Module, ModuleHeader } from '../../shared';

export default class GeneralAcctInfoPanel extends React.Component {
  public render() {
    return (
      <Flex>
        <Box>
          <Module>
            <ModuleHeader>General account information</ModuleHeader>
          </Module>
        </Box>
      </Flex>
    );
  }
}
