import { distanceInWordsToNow, format } from 'date-fns';
import * as React from 'react';
import { connect } from 'react-redux';
import { Scopes } from '../../auth';
import history from '../../history';
import { AppState } from '../../reducer';
import { Settings } from '../../settings';
import { LoadingError, RequestError, ResourceNotFound } from '../../shared';
import { Actions } from '../actions';
import { CSVExport } from '../components/CSVExport';
import { MembersPanel } from '../components/MembersPanel';
import {
  Account,
  CustomRole,
  getAccountDisplayName,
  getMemberDisplayName,
  Member,
  MemberSessions,
  MembersPaginationData,
  TeamsData,
} from '../model';
import {
  getCombinedMembersResults,
  getMembersForExport,
  getMembersPaginationData,
  getMembersRequestByAccountId,
} from '../selectors';

type StateProps = Readonly<{
  isFetching: boolean;
  error: RequestError;
  members: Member[];
  membersForExport: Member[];
  paginationData: MembersPaginationData;
}>;

type OwnProps = Readonly<{
  account: Account;
  settings: Settings;
  scopes: Scopes;
  memberSessions: MemberSessions[];
  customRoles: { [accountId: string]: CustomRole };
  onSetMfaDisabled: (account: Account, member: Member) => void;
}>;

type DispatchProps = Readonly<{
  fetchMembers: (account: Account, url?: string) => void;
  fetchMembersForExport: (account: Account) => void;
  searchMembers: (account: Account, query: string) => void;
}>;

type MembersPanelContainerProps = DispatchProps & StateProps & OwnProps;

export class MembersPanelContainer extends React.Component<MembersPanelContainerProps, any> {
  public componentWillMount() {
    const { fetchMembers, fetchMembersForExport, account } = this.props;
    fetchMembers(account, this.checkUrlForPaginationData());
    fetchMembersForExport(account);
  }

  public render() {
    const {
      isFetching,
      error,
      account,
      settings,
      scopes,
      memberSessions,
      customRoles,
      onSetMfaDisabled,
      members,
      paginationData,
    } = this.props;

    if (error) {
      switch (error.status) {
        case 404:
          return (
            <ResourceNotFound
              title="Account members not found"
              description={<p>Members not found</p>}
              returnTo="/accounts"
              returnLabel="Go back to accounts"
            />
          );
        default:
          return <LoadingError title="Failed to load members" />;
      }
    }

    return (
      <>
        <MembersPanel
          account={account}
          settings={settings}
          scopes={scopes}
          memberSessions={memberSessions}
          members={members}
          customRoles={customRoles}
          onSetMfaDisabled={onSetMfaDisabled}
          handlePaginateMembers={this.handlePaginateMembers}
          handleSearchMembers={this.handleSearchMembers}
          getMemberSession={this.getMemberSession}
          getMemberLastActive={this.getMemberLastActive}
          customRolesForMemberInTeams={this.customRolesForMemberInTeams}
          paginationData={paginationData}
          isFetching={isFetching}
        />
        {settings.enableMemberSessions && this.exportAsCsv()}
      </>
    );
  }

  public handleSearchMembers = (query: string) => {
    const { searchMembers, account } = this.props;
    searchMembers(account, query);
  };

  public handlePaginateMembers = (url: string) => {
    const offset = url.split('=')[2];
    if (offset) {
      history.push(`/accounts/${this.props.account._id}/members?offset=${offset}`);
    } else {
      history.push(`/accounts/${this.props.account._id}`);
    }
    this.props.fetchMembers(this.props.account, url);
  };

  public customRolesForMemberInTeams = (member: Member, customRoles: { [accountId: string]: CustomRole }) => {
    const roleKeysFromTeams = member.teams
      ? member.teams
          .map((team: TeamsData) => team.customRoleKeys)
          .reduce((roleKeys: string[], roleKey) => roleKeys.concat(roleKey), [])
      : [];
    return Object.values(customRoles).filter((role: CustomRole) => {
      return roleKeysFromTeams.includes(role.key);
    });
  };

  private checkUrlForPaginationData = () => {
    const offsetFromQueryParams = new URL(window.location.href).href.split('offset=')[1];
    if (typeof offsetFromQueryParams === 'string') {
      return `/api/v2/members?limit=20&offset=${offsetFromQueryParams}`;
    } else {
      return undefined;
    }
  };

  public getMemberSession = (memberId: string) => {
    const { memberSessions } = this.props;
    return memberSessions.filter(session => session.memberId === memberId);
  };

  public getMemberLastActive = (memberId: string, dateFormat: string) => {
    const memberSession = this.getMemberSession(memberId);
    if (memberSession.length) {
      if (dateFormat === 'MDY') {
        return format(memberSession[0].lastActiveDate, 'MM/DD/YYYY');
      }
      if (dateFormat === 'timeAgo') {
        return distanceInWordsToNow(memberSession[0].lastActiveDate, { addSuffix: true });
      }
    }
    return 'No activity';
  };

  private exportAsCsv = () => {
    const { account, membersForExport } = this.props;
    const headerRows = ['Name', 'Last active', 'ID'];
    const memberRows = membersForExport.map(m => {
      return [getMemberDisplayName(m), this.getMemberLastActive(m._id, 'MDY'), m._id];
    });
    const fileName = `${getAccountDisplayName(account)}-Members`;
    return (
      <CSVExport
        fileName={fileName}
        headerRows={headerRows}
        tableRows={memberRows}
        buttonText="Export All Members as CSV"
      />
    );
  };
}

const mapStateToProps = (state: AppState, props: OwnProps) => ({
  members: getCombinedMembersResults(state, { accountId: props.account._id }), // Use combined selector
  membersForExport: getMembersForExport(state, { accountId: props.account._id }),
  paginationData: getMembersPaginationData(state, { accountId: props.account._id }),
  ...getMembersRequestByAccountId(state, { accountId: props.account._id }),
});

const mapDispatchToProps = {
  fetchMembers: Actions.fetchMembers,
  fetchMembersForExport: Actions.fetchMembersForExport,
  searchMembers: Actions.searchMembers,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MembersPanelContainer);
