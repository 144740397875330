import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { concat, of, timer } from 'rxjs';
import { catchError, filter, flatMap, map, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import * as gonfalon from '../gonfalon';
import { AppState } from '../reducer';
import { RequestError } from '../shared';
import { Actions, ActionTypes } from './actions';
import * as api from './api';
import { Account, LegacySubscription, Subscription } from './model';

export const accountsFetch = (action$: ActionsObservable<Actions>) => {
  return action$.pipe(
    ofType<ReturnType<typeof Actions.fetchAccounts>>(ActionTypes.FETCH_ACCOUNTS),
    switchMap(({ q, unpaid, overage }) =>
      api.getAccounts({ q, unpaid, overage }).pipe(
        map(response => Actions.receiveAccounts(q, response)),
        catchError(error => of(Actions.fetchAccountsFailed(q, error))),
      ),
    ),
  );
};

export const accountFetch = (action$: ActionsObservable<Actions>, state$: StateObservable<AppState>) => {
  return action$.pipe(
    ofType<ReturnType<typeof Actions.fetchAccount>>(ActionTypes.FETCH_ACCOUNT),
    flatMap(({ accountId }) =>
      api.getAccount(accountId).pipe(
        withLatestFrom(state$),
        flatMap(([response, state]) => {
          const account = response.entities.accounts[accountId];
          return concat(
            of(Actions.fetchLegacySubscription(account)),
            of(Actions.fetchSubscription(account)),
            of(Actions.fetchSubscriptionUsage(account)),
            of(Actions.fetchMemberSessions(account, state.settings.enableMemberSessions)),
            of(Actions.fetchCustomRoles(account)),
            of(Actions.fetchProjects(account)),
            of(Actions.receiveAccount(accountId, response)),
          );
        }),
        catchError(error => of(Actions.fetchAccountFailed(accountId, error))),
      ),
    ),
  );
};

export const accountLegacySubscriptionFetch = (action$: ActionsObservable<Actions>) => {
  return action$.pipe(
    ofType<ReturnType<typeof Actions.fetchLegacySubscription>>(ActionTypes.FETCH_LEGACY_SUBSCRIPTION),
    flatMap(({ account }) =>
      api.getLegacyAccountSubscription(account).pipe(
        map(response => Actions.receiveLegacySubscription(account, response)),
        catchError(error => of(Actions.fetchLegacySubscriptionFailed(account, error))),
      ),
    ),
  );
};

export const accountSubscriptionFetch = (action$: ActionsObservable<Actions>) => {
  return action$.pipe(
    ofType<ReturnType<typeof Actions.fetchSubscription>>(ActionTypes.FETCH_SUBSCRIPTION),
    flatMap(({ account }) =>
      api.getAccountSubscription(account).pipe(
        map(response => Actions.receiveSubscription(account, response)),
        catchError(error => of(Actions.fetchSubscriptionFailed(account, error))),
      ),
    ),
  );
};

export const accountSubscriptionUsageFetch = (action$: ActionsObservable<Actions>) => {
  return action$.pipe(
    ofType<ReturnType<typeof Actions.fetchSubscriptionUsage>>(ActionTypes.FETCH_SUBSCRIPTION_USAGE),
    flatMap(({ account }) =>
      api.getAccountSubscriptionUsage(account).pipe(
        map(response => Actions.receiveSubscriptionUsage(account, response)),
        catchError(error => of(Actions.fetchSubscriptionUsageFailed(account, error))),
      ),
    ),
  );
};

export const accountMembersFetch = (action$: ActionsObservable<Actions>, state$: StateObservable<AppState>) => {
  return action$.pipe(
    ofType<ReturnType<typeof Actions.fetchMembers>>(ActionTypes.FETCH_MEMBERS),
    withLatestFrom(state$),
    flatMap(([response, state]) => {
      const { account, url } = response;
      return api.getAccountMembers(account, state.settings.enablePaginationOfMembers, url).pipe(
        flatMap(accountMembersResponse => {
          return concat(
            of(Actions.receiveMembers(account, accountMembersResponse)),
            of(Actions.receiveMembersPaginationData(account, accountMembersResponse)),
          );
        }),
        catchError(error => of(Actions.fetchMembersFailed(account, error))),
      );
    }),
  );
};

export const accountMembersForExportFetch = (action$: ActionsObservable<Actions>) => {
  return action$.pipe(
    ofType<ReturnType<typeof Actions.fetchMembersForExport>>(ActionTypes.FETCH_MEMBERS_FOR_EXPORT),
    flatMap(({ account }) =>
      api.getAccountMembers(account, false).pipe(
        map(response => Actions.receiveMembersForExport(account, response)),
        catchError(error => of(Actions.fetchMembersForExportFailed(account, error))),
      ),
    ),
  );
};

export const accountMemberSessionsFetch = (action$: ActionsObservable<Actions>) => {
  return action$.pipe(
    ofType<{ account: Account; type: ActionTypes.FETCH_MEMBER_SESSIONS }>(ActionTypes.FETCH_MEMBER_SESSIONS),
    flatMap(({ account }) =>
      api.getAccountMemberSessions(account).pipe(
        map(response => Actions.receiveMemberSessions(account, response)),
        catchError(error => of(Actions.fetchMemberSessionsFailed(account, error))),
      ),
    ),
  );
};

export const accountCustomRolesFetch = (action$: ActionsObservable<Actions>) => {
  return action$.pipe(
    ofType<ReturnType<typeof Actions.fetchCustomRoles>>(ActionTypes.FETCH_CUSTOM_ROLES),
    flatMap(({ account }) =>
      api.getAccountCustomRoles(account).pipe(
        map(response => Actions.receiveCustomRoles(account, response)),
        catchError(error => of(Actions.fetchCustomRolesFailed(account, error))),
      ),
    ),
  );
};

export const accountUsageFetch = (action$: ActionsObservable<Actions>) => {
  return action$.pipe(
    ofType<ReturnType<typeof Actions.fetchAccountUsage>>(ActionTypes.REQUEST_ACCOUNT_USAGE),
    flatMap(({ account, usageIdentifier, usageType, mauType, metric, filters, settings }) =>
      api.getAccountUsage(account, usageType, mauType, metric, filters, settings).pipe(
        map(response => {
          return Actions.receiveUsage(response, usageIdentifier);
        }),
        catchError(error => of(Actions.fetchUsageFailed(error, usageIdentifier))),
      ),
    ),
  );
};

export const accountHighUsageFetch = (action$: ActionsObservable<Actions>) => {
  return action$.pipe(
    ofType<ReturnType<typeof Actions.fetchHighAccountUsage>>(ActionTypes.REQUEST_HIGH_ACCOUNT_USAGE),
    flatMap(({ usageType, dates }) =>
      gonfalon.getHighAccountUsage(usageType, dates).pipe(
        map(response => {
          return Actions.receiveHighAccountUsage(response);
        }),
        catchError(error => of(Actions.fetchHighAccountUsageFailed(error))),
      ),
    ),
  );
};

export const sdkVersionsFetch = (action$: ActionsObservable<Actions>) => {
  return action$.pipe(
    ofType<ReturnType<typeof Actions.fetchSDKVersions>>(ActionTypes.REQUEST_SDK_VERSIONS),
    flatMap(({ account, sdkType }) =>
      api.getSdkVersions(account, sdkType).pipe(
        map(response => {
          return Actions.receiveSDKVersions(response, sdkType);
        }),
        catchError(error => of(Actions.fetchSDKVersionsFailed(error, sdkType))),
      ),
    ),
  );
};

export const accountProjectsFetch = (action$: ActionsObservable<Actions>) => {
  return action$.pipe(
    ofType<ReturnType<typeof Actions.fetchProjects>>(ActionTypes.FETCH_PROJECTS),
    flatMap(({ account }) =>
      api.getAccountProjects(account).pipe(
        map(response => Actions.receiveProjects(account, response)),
        catchError(error => of(Actions.fetchProjectsFailed(account, error))),
      ),
    ),
  );
};

export const accountAuditLogEntriesFetch = (action$: ActionsObservable<Actions>) => {
  return action$.pipe(
    ofType<ReturnType<typeof Actions.fetchAuditLogEntries>>(ActionTypes.FETCH_AUDIT_LOG_ENTRIES),
    flatMap(({ account, nextUrl, query, spec, date }) =>
      api.getAccountAuditLogEntries({ account, nextUrl, query, spec, date }).pipe(
        map(response => Actions.receiveAuditLogEntries(account, response)),
        catchError(error => of(Actions.fetchAuditLogEntriesFailed(account, error))),
      ),
    ),
  );
};

export const accountAuditLogEntryDetailsFetch = (action$: ActionsObservable<Actions>) => {
  return action$.pipe(
    ofType<ReturnType<typeof Actions.fetchAuditLogEntryDetail>>(ActionTypes.FETCH_AUDIT_LOG_ENTRY_DETAIL),
    flatMap(({ account, entryUrl }) =>
      api.getAccountAuditLogEntryDetail(account, entryUrl).pipe(
        map(response => Actions.receiveAuditLogEntryDetail(account, response)),
        catchError(error => of(Actions.fetchAuditLogEntryDetailFailed(account, error))),
      ),
    ),
  );
};

export const accountSearch = (action$: ActionsObservable<Actions>) => {
  return action$.pipe(
    ofType<ReturnType<typeof Actions.searchAccounts>>(ActionTypes.SEARCH_ACCOUNTS),
    filter(({ q }) => !!q),
    switchMap(({ q }) =>
      timer(500).pipe(
        takeUntil(action$.ofType(ActionTypes.CLEAR_ACCOUNT_RESULTS)),
        flatMap(() => api.getAccounts({ q }).pipe(map(Response => Actions.receiveSearchResults(q, Response)))),
      ),
    ),
  );
};

export const accountSearchClearance = (action$: ActionsObservable<Actions>) => {
  return action$.pipe(
    ofType<ReturnType<typeof Actions.searchAccounts>>(ActionTypes.SEARCH_ACCOUNTS),
    filter(action => action.q === ''),
    flatMap(() => of(Actions.clearAccountResults())),
  );
};

const subscriptionDateUpdates = (
  initialAction: ActionTypes,
  updateDate: (subscription: Subscription, date: Date) => object,
  onSuccess: (account: Account, subscription: Subscription, updatedSubscription: Subscription) => any,
  onError: (account: Account, error: RequestError) => any,
) => (action$: ActionsObservable<any>) => {
  return action$.ofType(initialAction).pipe(
    flatMap(action => {
      const { account, subscription, date } = action;
      const updatedDate = updateDate(subscription, date);
      return api.changeAccountSubscriptionDates(account, updatedDate).pipe(
        map(response => onSuccess(action.account as Account, action.subscription, response)),
        catchError((error: RequestError) => of(onError(account, error))),
      );
    }),
  );
};

export const subscriptionTrialEndUpdates = subscriptionDateUpdates(
  ActionTypes.UPDATE_TRIAL_END,
  (subscription, date) => ({
    trialStartDate: subscription.trialStartDate,
    trialEndDate: date.toISOString(),
  }),
  Actions.updateTrialEndSucceeded,
  Actions.updateTrialEndFailed,
);

export const subscriptionGraceEndUpdates = subscriptionDateUpdates(
  ActionTypes.UPDATE_GRACE_END,
  (subscription, date) => ({
    graceStartDate: subscription.graceStartDate,
    graceEndDate: date.toISOString(),
  }),
  Actions.updateGraceEndSucceeded,
  Actions.updateGraceEndFailed,
);

const legacySubscriptionDateUpdates = (
  initialAction: ActionTypes,
  updateSubscription: (subscription: LegacySubscription, date: Date) => LegacySubscription,
  onSuccess: (account: Account, subscription: LegacySubscription, updatedSubscription: LegacySubscription) => any,
  onError: (account: Account, error: RequestError) => any,
) => (action$: ActionsObservable<any>) => {
  return action$.ofType(initialAction).pipe(
    flatMap(action => {
      const { account, subscription } = action;
      const newSubscription = updateSubscription(action.subscription, action.date);

      return api.patchLegacyAccountSubscription(account, subscription, newSubscription).pipe(
        map(response => onSuccess(action.account as Account, action.subscription, response)),
        catchError((error: RequestError) => of(onError(action.account as Account, error))),
      );
    }),
  );
};

export const legacySubscriptionTrialEndUpdates = legacySubscriptionDateUpdates(
  ActionTypes.UPDATE_LEGACY_TRIAL_END,
  (subscription, date) => ({
    ...subscription,
    trialEndDate: date.toISOString(),
  }),
  Actions.updateLegacyTrialEndSucceeded,
  Actions.updateLegacyTrialEndFailed,
);

export const setFeatureTrial = (action$: ActionsObservable<Actions>) =>
  action$.pipe(
    ofType<ReturnType<typeof Actions.setFeatureTrial>>(ActionTypes.SET_FEATURE_TRIAL),
    flatMap(({ account, flagKey }) =>
      api.patchTrialFeature(account, flagKey).pipe(
        map(updatedAccount => Actions.setFeatureTrialSuccess(updatedAccount)),
        catchError((error: RequestError) => of(Actions.setFeatureTrialFailed(account, error))),
      ),
    ),
  );

export const getScheduledTrials = (action$: ActionsObservable<Actions>) =>
  action$.pipe(
    ofType<ReturnType<typeof Actions.fetchScheduledTrials>>(ActionTypes.GET_SCHEDULED_TRIALS),
    flatMap(({ account, flagKey }) =>
      api
        .getScheduledFlagsByKey(account, flagKey)
        .pipe(map(response => Actions.getScheduledTrialsSuccess(account, response, flagKey))),
    ),
  );

export const setFeatureExpirationDate = (action$: ActionsObservable<Actions>) =>
  action$.pipe(
    ofType<ReturnType<typeof Actions.setFeatureExpirationDate>>(ActionTypes.SET_FEATURE_EXPRIATION_DATE),
    flatMap(({ account, flagKey, date }) =>
      api.postTrialFeatureExpiration(account, flagKey, date).pipe(
        map(updatedAccount => Actions.setFeatureExpirationDateSuccess(updatedAccount)),
        catchError((error: RequestError) => of(Actions.setFeatureTrialFailed(account, error))),
      ),
    ),
  );

export const legacySubscriptionGraceEndUpdates = legacySubscriptionDateUpdates(
  ActionTypes.UPDATE_LEGACY_GRACE_END,
  (subscription, date) => ({
    ...subscription,
    graceEndDate: date.toISOString(),
  }),
  Actions.updateLegacyGraceEndSucceeded,
  Actions.updateLegacyGraceEndFailed,
);

const enterpriseTrialCampaignDateUpdates = (
  initialAction: ActionTypes,
  updateDate: (subscription: Subscription, date: Date) => { startTime: string; endTime: string },
  onSuccess: (account: Account, subscription: Subscription, updatedSubscription: Subscription) => any,
  onError: (account: Account, error: RequestError) => any,
) => (action$: ActionsObservable<any>) => {
  return action$.ofType(initialAction).pipe(
    flatMap(action => {
      const { account, subscription, date } = action;
      const updatedDate = updateDate(subscription, date);
      return api.changeEnterpriseTrialCampaignDates(account, updatedDate).pipe(
        map(response => onSuccess(action.account as Account, action.subscription, response)),
        catchError((error: RequestError) => of(onError(account, error))),
      );
    }),
  );
};

export const enterpriseTrialCampaignUpdates = enterpriseTrialCampaignDateUpdates(
  ActionTypes.UPDATE_ENTERPRISE_CAMPAIGN_DATES,
  (subscription, date) => ({
    startTime: subscription.campaigns.CustomerEnterpriseCampaign.startDate,
    endTime: date.toISOString(),
  }),
  Actions.updateEnterpriseCampaignDatesSucceeded,
  Actions.updateEnterpriseCampaignDatesFailed,
);

export const updateAccountOwner = (action$: ActionsObservable<Actions>) =>
  action$.pipe(
    ofType<ReturnType<typeof Actions.updateAccountOwner>>(ActionTypes.UPDATE_ACCOUNT_OWNER),
    flatMap(({ account, promotion }) =>
      api.putAccountOwner(account, promotion).pipe(
        map(response => Actions.updateAccountOwnerSucceeded(account, response)),
        catchError((error: RequestError) => of(Actions.updateAccountOwnerFailed(account, error))),
      ),
    ),
  );

export const subscribe = (action$: ActionsObservable<Actions>) =>
  action$.pipe(
    ofType<ReturnType<typeof Actions.subscribe>>(ActionTypes.SUBSCRIBE),
    flatMap(({ account, subscription, plan }) =>
      api.putSubscription(account, plan).pipe(
        map(updatedSubscription => Actions.subscribeSucceeded(account, subscription, updatedSubscription)),
        catchError((error: RequestError) => of(Actions.subscribeFailed(account, plan, error))),
      ),
    ),
  );

export const cancelSubscription = (action$: ActionsObservable<Actions>) =>
  action$.pipe(
    ofType<ReturnType<typeof Actions.cancelSubscription>>(ActionTypes.CANCEL_SUBSCRIPTION),
    flatMap((
      { account, endImmediately }, // Extract endImmediately from action
    ) =>
      api.cancelSubscription(account, endImmediately).pipe(
        map(() => Actions.cancelSubscriptionFulfilled(account)),
        catchError((error: RequestError) => of(Actions.cancelSubscriptionFailed(account, error))),
      ),
    ),
  );

export const updateStripeSubscriptionId = (action$: ActionsObservable<Actions>) =>
  action$.pipe(
    ofType<ReturnType<typeof Actions.updateStripeSubscriptionId>>(ActionTypes.UPDATE_STRIPE_SUBSCRIPTION_ID),
    flatMap(({ account, subscription, stripeSubscriptionId }) =>
      api.patchAccountSubscription(account, subscription, { ...subscription, stripeSubscriptionId }).pipe(
        map(updatedSubscription => Actions.updateStripeSubscriptionIdFulfilled(account, updatedSubscription)),
        catchError((error: RequestError) => of(Actions.updateStripeSubscriptionIdFailed(account, error))),
      ),
    ),
  );

export const clearPendingSubscriptionUpdate = (action$: ActionsObservable<Actions>) =>
  action$.pipe(
    ofType<ReturnType<typeof Actions.clearPendingSubscriptionUpdate>>(ActionTypes.CLEAR_PENDING_SUBSCRIPTION_UPDATE),
    flatMap(({ account, subscription }) =>
      api.patchAccountSubscription(account, subscription, { ...subscription, pendingUpdate: false }).pipe(
        map(updatedSubscription => Actions.clearPendingSubscriptionUpdateFulfilled(account, updatedSubscription)),
        catchError((error: RequestError) => of(Actions.clearPendingSubscriptionUpdateFailed(account, error))),
      ),
    ),
  );

export const disableMfa = (action$: ActionsObservable<Actions>) =>
  action$.pipe(
    ofType<ReturnType<typeof Actions.setMfaDisabled>>(ActionTypes.DISABLE_MFA),
    flatMap(({ account, member }) =>
      api.postProfileMfaDisable(account, member).pipe(
        map(response => Actions.setMfaDisabledSuccess(account, response)),
        catchError((error: RequestError) => of(Actions.setMfaDisabledFailed(account, member, error))),
      ),
    ),
  );

export const disableSsoSet = (action$: ActionsObservable<Actions>) =>
  action$.pipe(
    ofType<ReturnType<typeof Actions.setSsoDisabled>>(ActionTypes.SET_SSO_DISABLED),
    flatMap(({ account }) =>
      api.postAccountSsoDisable(account).pipe(
        map(response => Actions.setSsoDisabledSuccess(account, response)),
        catchError((error: RequestError) => of(Actions.setSsoDisabledFailed(account, error))),
      ),
    ),
  );

export const accountMembersSearchEpic = (
  action$: ActionsObservable<{ type: string; account?: Account; query?: string }>,
) => {
  return action$.pipe(
    ofType(ActionTypes.SEARCH_MEMBERS),
    switchMap(action => {
      const { account, query } = action;
      return api.searchMembers(account!, query!).pipe(
        map(response => Actions.searchMembersFulfilled(account!, query!, response)),
        catchError(error => of(Actions.searchMembersFailed(account!, error))),
      );
    }),
  );
};
