import { Settings } from '../settings';
import { ActionsUnion, makeActionCreator, RequestError } from '../shared';
import {
  AccountListResponse,
  AccountResponse,
  AuditLogEntryDetailResponse,
  AuditLogEntryResponse,
  CustomRoleResponse,
  MemberResponse,
  MemberSessionsResponse,
  ProjectResponse,
} from './api';
import {
  Account,
  LegacySubscription,
  Member,
  MetricType,
  OwnerPromotion,
  Plan,
  SamlConfig,
  SavedAccount,
  SDKType,
  Subscription,
  SubscriptionUsage,
  UsageFilters,
  UsageIdentifier,
  UsageOrigin,
  UsageTypes,
} from './model';

export enum ActionTypes {
  ADD_FAVORITED_ACCOUNT = 'accounts/ADD_FAVORITED_ACCOUNT',
  REMOVE_FAVORITED_ACCOUNT = 'accounts/REMOVE_FAVORITED_ACCOUNT',
  GET_FAVORITED_ACCOUNT = 'accounts/GET_FAVORITED_ACCOUNT',
  SEARCH_ACCOUNTS = 'accounts/SEARCH_ACCOUNTS',
  RECEIVE_ACCOUNT_RESULTS = 'accounts/RECEIVE_ACCOUNT_RESULTS',
  CLEAR_ACCOUNT_RESULTS = 'accounts/CLEAR_SEARCH_RESULTS',
  FETCH_ACCOUNTS = 'accounts/FETCH_ACCOUNTS',
  FETCH_ACCOUNTS_FULFILLED = 'accounts/FETCH_ACCOUNTS_FULFILLED',
  FETCH_ACCOUNTS_FAILED = 'accounts/FETCH_ACCOUNTS_FAILED',
  FETCH_ACCOUNT = 'accounts/FETCH_ACCOUNT',
  FETCH_ACCOUNT_FULFILLED = 'accounts/FETCH_ACCOUNT_FULFILLED',
  FETCH_ACCOUNT_FAILED = 'accounts/FETCH_ACCOUNT_FAILED',
  FETCH_LEGACY_SUBSCRIPTION = 'accounts/FETCH_LEGACY_SUBSCRIPTION',
  FETCH_LEGACY_SUBSCRIPTION_FULFILLED = 'accounts/FETCH_LEGACY_SUBSCRIPTION_FULFILLED',
  FETCH_LEGACY_SUBSCRIPTION_FAILED = 'accounts/FETCH_LEGACY_SUBSCRIPTION_FAILED',
  FETCH_SUBSCRIPTION = 'accounts/FETCH_SUBSCRIPTION',
  FETCH_SUBSCRIPTION_FULFILLED = 'accounts/FETCH_SUBSCRIPTION_FULFILLED',
  FETCH_SUBSCRIPTION_FAILED = 'accounts/FETCH_SUBSCRIPTION_FAILED',
  FETCH_SUBSCRIPTION_USAGE = 'accounts/FETCH_SUBSCRIPTION_USAGE',
  FETCH_SUBSCRIPTION_USAGE_FULFILLED = 'accounts/FETCH_SUBSCRIPTION_USAGE_FULFILLED',
  FETCH_SUBSCRIPTION_USAGE_FAILED = 'accounts/FETCH_SUBSCRIPTION_USAGE_FAILED',
  FETCH_MEMBERS_PAGINATION_DATA_FULFILLED = 'accounts/FETCH_MEMBERS_PAGINATION_DATA_FULFILLED',
  FETCH_MEMBERS = 'accounts/FETCH_MEMBERS',
  FETCH_MEMBERS_FULFILLED = 'accounts/FETCH_MEMBERS_FULFILLED',
  FETCH_MEMBERS_FAILED = 'accounts/FETCH_MEMBERS_FAILED',
  FETCH_MEMBERS_FOR_EXPORT = 'accounts/FETCH_MEMBERS_FOR_EXPORT',
  FETCH_MEMBERS_FOR_EXPORT_FULFILLED = 'accounts/FETCH_MEMBERS_FOR_EXPORT_FULFILLED',
  FETCH_MEMBERS_FOR_EXPORT_FAILED = 'accounts/FETCH_MEMBERS_FOR_EXPORT_FAILED',
  FETCH_MEMBER_SESSIONS = 'accounts/FETCH_MEMBER_SESSIONS',
  FETCH_MEMBER_SESSIONS_FULFILLED = 'accounts/FETCH_MEMBER_SESSIONS_FULFILLED',
  FETCH_MEMBER_SESSIONS_FAILED = 'accounts/FETCH_MEMBER_SESSIONS_FAILED',
  FETCH_CUSTOM_ROLES = 'accounts/FETCH_CUSTOM_ROLES',
  FETCH_CUSTOM_ROLES_FULFILLED = 'accounts/FETCH_CUSTOM_ROLES_FULFILLED',
  FETCH_CUSTOM_ROLES_FAILED = 'accounts/FETCH_CUSTOM_ROLES_FAILED',
  FETCH_PROJECTS = 'accounts/FETCH_PROJECTS',
  FETCH_PROJECTS_FULFILLED = 'accounts/FETCH_PROJECTS_FULFILLED',
  FETCH_PROJECTS_FAILED = 'accounts/FETCH_PROJECTS_FAILED',
  FETCH_AUDIT_LOG_ENTRIES = 'accounts/FETCH_AUDIT_LOG_ENTRIES',
  FETCH_AUDIT_LOG_ENTRIES_FULFILLED = 'accounts/FETCH_AUDIT_LOG_ENTRIES_FULFILLED',
  FETCH_AUDIT_LOG_ENTRIES_FAILED = 'accounts/FETCH_AUDIT_LOG_ENTRIES_FAILED',
  FETCH_AUDIT_LOG_ENTRY_DETAIL = 'accounts/FETCH_AUDIT_LOG_ENTRY_DETAIL',
  FETCH_AUDIT_LOG_ENTRY_DETAIL_FULFILLED = 'accounts/FETCH_AUDIT_LOG_ENTRY_DETAIL_FULFILLED',
  FETCH_AUDIT_LOG_ENTRY_DETAIL_FAILED = 'accounts/FETCH_AUDIT_LOG_ENTRY_DETAIL_FAILED',
  NO_OP = 'accounts/NO_OP',
  UPDATE_ACCOUNT_OWNER = 'accounts/UPDATE_ACCOUNT_OWNER',
  UPDATE_ACCOUNT_OWNER_FULFILLED = 'accounts/UPDATE_ACCOUNT_OWNER_FULFILLED',
  UPDATE_ACCOUNT_OWNER_FAILED = 'accounts/UPDATE_ACCOUNT_OWNER_FAILED',
  UPDATE_TRIAL_END = 'accounts/UPDATE_TRIAL_END',
  UPDATE_TRIAL_END_FULFILLED = 'accounts/UPDATE_TRIAL_END_FULFILLED',
  UPDATE_TRIAL_END_FAILED = 'accounts/UPDATE_TRIAL_END_FAILED',
  UPDATE_GRACE_END = 'accounts/UPDATE_GRACE_END',
  UPDATE_GRACE_END_FULFILLED = 'accounts/UPDATE_GRACE_END_FULFILLED',
  UPDATE_GRACE_END_FAILED = 'accounts/UPDATE_GRACE_END_FAILED',
  UPDATE_LEGACY_TRIAL_END = 'accounts/UPDATE_LEGACY_TRIAL_END',
  UPDATE_LEGACY_TRIAL_END_FULFILLED = 'accounts/UPDATE_LEGACY_TRIAL_END_FULFILLED',
  UPDATE_LEGACY_TRIAL_END_FAILED = 'accounts/UPDATE_LEGACY_TRIAL_END_FAILED',
  UPDATE_LEGACY_GRACE_END = 'accounts/UPDATE_LEGACY_GRACE_END',
  UPDATE_LEGACY_GRACE_END_FULFILLED = 'accounts/UPDATE_LEGACY_GRACE_END_FULFILLED',
  UPDATE_LEGACY_GRACE_END_FAILED = 'accounts/UPDATE_LEGACY_GRACE_END_FAILED',
  UPDATE_ENTERPRISE_CAMPAIGN_DATES = 'accounts/UPDATE_ENTERPRISE_CAMPAIGN_DATES',
  UPDATE_ENTERPRISE_CAMPAIGN_DATES_FAILED = 'accounts/UPDATE_ENTERPRISE_CAMPAIGN_DATES_FAILED',
  UPDATE_ENTERPRISE_CAMPAIGN_DATES_FULFILLED = 'accounts/UPDATE_ENTERPRISE_CAMPAIGN_DATES_FULFILLED',
  REQUEST_ACCOUNT_USAGE = 'accounts/REQUEST_ACCOUNT_USAGE',
  REQUEST_ACCOUNT_USAGE_FULFILLED = 'accounts/REQUEST_ACCOUNT_USAGE_FUFILLED',
  REQUEST_ACCOUNT_USAGE_FAILED = 'accounts/REQUEST_ACCOUNT_USAGE_FAILED',
  REQUEST_SDK_VERSIONS = 'accounts/REQUEST_SDK_VERSIONS',
  REQUEST_SDK_VERSIONS_FULFILLED = 'accounts/REQUEST_SDK_VERSIONS_FUFILLED',
  REQUEST_SDK_VERSIONS_FAILED = 'accounts/REQUEST_SDK_VERSIONS_FAILED',
  REQUEST_HIGH_ACCOUNT_USAGE = 'accounts/REQUEST_HIGH_ACCOUNT_USAGE',
  REQUEST_HIGH_ACCOUNT_USAGE_FULFILLED = 'accounts/REQUEST_HIGH_ACCOUNT_USAGE_FUFILLED',
  REQUEST_HIGH_ACCOUNT_USAGE_FAILED = 'accounts/REQUEST_HIGH_ACCOUNT_USAGE_FAILED',
  SUBSCRIBE = 'accounts/SUBSCRIBE',
  SUBSCRIBE_FULFILLED = 'accounts/SUBSCRIBE_FUFILLED',
  SUBSCRIBE_FAILED = 'accounts/SUBSCRIBE_FAILED',
  CANCEL_SUBSCRIPTION = 'accounts/CANCEL_SUBSCRIPTION',
  CANCEL_SUBSCRIPTION_FULFILLED = 'accounts/CANCEL_SUBSCRIPTION_FULFILLED',
  CANCEL_SUBSCRIPTION_FAILED = 'accounts/CANCEL_SUBSCRIPTION_FAILED',
  UPDATE_STRIPE_SUBSCRIPTION_ID = 'accounts/UPDATE_STRIPE_SUBSCRIPTION_ID',
  UPDATE_STRIPE_SUBSCRIPTION_ID_FULFILLED = 'accounts/UPDATE_STRIPE_SUBSCRIPTION_ID_FULFILLED',
  UPDATE_STRIPE_SUBSCRIPTION_ID_FAILED = 'accounts/UPDATE_STRIPE_SUBSCRIPTION_ID_FAILED',
  CLEAR_PENDING_SUBSCRIPTION_UPDATE = 'accounts/CLEAR_PENDING_SUBSCRIPTION_UPDATE',
  CLEAR_PENDING_SUBSCRIPTION_UPDATE_FULFILLED = 'accounts/CLEAR_PENDING_SUBSCRIPTION_UPDATE_FULFILLED',
  CLEAR_PENDING_SUBSCRIPTION_UPDATE_FAILED = 'accounts/CLEAR_PENDING_SUBSCRIPTION_UPDATE_FAILED',
  ADD_ACCOUNT_TO_RECENTLY_VIEWED = 'accounts/ADD_ACCOUNT_TO_RECENTLY_VIEWED',
  SET_FEATURE_TRIAL = 'accounts/SET_FEATURE_TRIAL',
  SET_FEATURE_TRIAL_SUCCESS = 'accounts/SET_FEATURE_TRIAL_SUCCESS',
  SET_FEATURE_TRIAL_FAILED = 'accounts/SET_FEATURE_TRIAL_FAILED',
  SET_FEATURE_EXPRIATION_DATE = 'accounts/SET_FEATURE_EXPRIATION_DATE',
  SET_FEATURE_EXPRIATION_DATE_SUCCESS = 'accounts/SET_FEATURE_EXPRIATION_DATE_SUCCESS',
  GET_SCHEDULED_TRIALS = 'accounts/GET_SCHEDULED_TRIALS',
  GET_SCHEDULED_TRIALS_SUCCESS = 'accounts/GET_SCHEDULED_TRIALS_SUCCESS',
  DISABLE_MFA = 'accounts/DISABLE_MFA',
  DISABLE_MFA_FULFILLED = 'accounts/DISABLE_MFA_FULFILLED',
  DISABLE_MFA_FAILED = 'accounts/DISABLE_MFA_FAILED',
  SET_SSO_DISABLED = 'accounts/SET_SSO_DISABLED',
  SET_SSO_DISABLED_FULFILLED = 'accounts/SET_SSO_DISABLED_FULFILLED',
  SET_SSO_DISABLED_FAILED = 'accounts/SET_SSO_DISABLED_FAILED',
  SEARCH_MEMBERS = 'members/SEARCH_MEMBERS',
  SEARCH_MEMBERS_FULFILLED = 'members/SEARCH_MEMBERS_FULFILLED',
  SEARCH_MEMBERS_FAILED = 'members/SEARCH_MEMBERS_FAILED',
}

const createAction = makeActionCreator<ActionTypes>();

export const Actions = {
  fetchAccounts: (q: string, unpaid: boolean, overage?: MetricType | 'any') =>
    createAction({
      type: ActionTypes.FETCH_ACCOUNTS,
      q,
      unpaid,
      overage,
    }),
  addFavoritedAccount: (favoritedAccount: SavedAccount) =>
    createAction({
      type: ActionTypes.ADD_FAVORITED_ACCOUNT,
      favoritedAccount,
    }),
  removeFavoritedAccount: (favoritedAccount: SavedAccount) =>
    createAction({
      type: ActionTypes.REMOVE_FAVORITED_ACCOUNT,
      favoritedAccount,
    }),
  getFavoritedAccount: () => createAction({ type: ActionTypes.GET_FAVORITED_ACCOUNT }),
  addAccountToRecentlyViewed: (recentlyViewedAccount: SavedAccount) =>
    createAction({
      type: ActionTypes.ADD_ACCOUNT_TO_RECENTLY_VIEWED,
      recentlyViewedAccount,
    }),
  searchAccounts: (q: string) =>
    createAction({
      type: ActionTypes.SEARCH_ACCOUNTS,
      q,
    }),
  receiveSearchResults: (q: string, response: AccountListResponse) =>
    createAction({
      type: ActionTypes.RECEIVE_ACCOUNT_RESULTS,
      q,
      response,
    }),
  clearAccountResults: () => createAction({ type: ActionTypes.CLEAR_ACCOUNT_RESULTS }),
  receiveAccounts: (q: string, response: AccountResponse) =>
    createAction({
      type: ActionTypes.FETCH_ACCOUNTS_FULFILLED,
      q,
      response,
    }),
  fetchAccountsFailed: (q: string, error: RequestError) =>
    createAction({
      type: ActionTypes.FETCH_ACCOUNTS_FAILED,
      q,
      error,
    }),
  setFeatureTrial: (account: Account, flagKey: string) =>
    createAction({
      type: ActionTypes.SET_FEATURE_TRIAL,
      account,
      flagKey,
    }),
  setFeatureTrialSuccess: (account: Account) =>
    createAction({
      type: ActionTypes.SET_FEATURE_TRIAL_SUCCESS,
      account,
    }),

  setFeatureTrialFailed: (account: Account, error: RequestError) =>
    createAction({
      type: ActionTypes.SET_FEATURE_TRIAL_FAILED,
      account,
      error,
    }),

  setFeatureExpirationDate: (account: Account, flagKey: string, date: Date) =>
    createAction({
      type: ActionTypes.SET_FEATURE_EXPRIATION_DATE,
      account,
      flagKey,
      date,
    }),

  fetchScheduledTrials: (account: Account, flagKey: string) =>
    createAction({
      type: ActionTypes.GET_SCHEDULED_TRIALS,
      account,
      flagKey,
    }),

  getScheduledTrialsSuccess: (account: Account, response: any, flagKey: string) =>
    createAction({
      type: ActionTypes.GET_SCHEDULED_TRIALS_SUCCESS,
      response,
      account,
      flagKey,
    }),

  setFeatureExpirationDateSuccess: (account: Account) =>
    createAction({
      type: ActionTypes.SET_FEATURE_EXPRIATION_DATE_SUCCESS,
      account,
    }),

  fetchAccount: (accountId: string) => createAction({ type: ActionTypes.FETCH_ACCOUNT, accountId }),
  receiveAccount: (accountId: string, response: AccountResponse) =>
    createAction({
      type: ActionTypes.FETCH_ACCOUNT_FULFILLED,
      accountId,
      response,
    }),
  fetchAccountFailed: (accountId: string, error: RequestError) =>
    createAction({
      type: ActionTypes.FETCH_ACCOUNT_FAILED,
      accountId,
      error,
    }),
  fetchLegacySubscription: (account: Account) =>
    createAction({
      type: ActionTypes.FETCH_LEGACY_SUBSCRIPTION,
      account,
    }),
  receiveLegacySubscription: (account: Account, response: LegacySubscription) =>
    createAction({
      type: ActionTypes.FETCH_LEGACY_SUBSCRIPTION_FULFILLED,
      account,
      response,
    }),
  fetchLegacySubscriptionFailed: (account: Account, error: RequestError) =>
    createAction({
      type: ActionTypes.FETCH_LEGACY_SUBSCRIPTION_FAILED,
      account,
      error,
    }),
  receiveMembersPaginationData: (account: Account, response: MemberResponse) =>
    createAction({
      type: ActionTypes.FETCH_MEMBERS_PAGINATION_DATA_FULFILLED,
      account,
      response,
    }),
  fetchMembers: (account: Account, url?: string) => createAction({ type: ActionTypes.FETCH_MEMBERS, account, url }),
  receiveMembers: (account: Account, response: MemberResponse) =>
    createAction({
      type: ActionTypes.FETCH_MEMBERS_FULFILLED,
      account,
      response,
    }),
  fetchMembersFailed: (account: Account, error: RequestError) =>
    createAction({
      type: ActionTypes.FETCH_MEMBERS_FAILED,
      account,
      error,
    }),
  fetchMembersForExport: (account: Account) => createAction({ type: ActionTypes.FETCH_MEMBERS_FOR_EXPORT, account }),
  receiveMembersForExport: (account: Account, response: MemberResponse) =>
    createAction({
      type: ActionTypes.FETCH_MEMBERS_FOR_EXPORT_FULFILLED,
      account,
      response,
    }),
  fetchMembersForExportFailed: (account: Account, error: RequestError) =>
    createAction({
      type: ActionTypes.FETCH_MEMBERS_FOR_EXPORT_FAILED,
      account,
      error,
    }),
  fetchMemberSessions: (account: Account, enableMemberSessions: boolean) => {
    if (!enableMemberSessions) {
      return createAction({ type: ActionTypes.NO_OP });
    }
    return createAction({ type: ActionTypes.FETCH_MEMBER_SESSIONS, account });
  },
  receiveMemberSessions: (account: Account, response: MemberSessionsResponse) =>
    createAction({
      type: ActionTypes.FETCH_MEMBER_SESSIONS_FULFILLED,
      account,
      response,
    }),
  fetchMemberSessionsFailed: (account: Account, error: RequestError) =>
    createAction({
      type: ActionTypes.FETCH_MEMBER_SESSIONS_FAILED,
      account,
      error,
    }),
  fetchCustomRoles: (account: Account) =>
    createAction({
      type: ActionTypes.FETCH_CUSTOM_ROLES,
      account,
    }),
  receiveCustomRoles: (account: Account, response: CustomRoleResponse) =>
    createAction({
      type: ActionTypes.FETCH_CUSTOM_ROLES_FULFILLED,
      account,
      response,
    }),
  fetchCustomRolesFailed: (account: Account, error: RequestError) =>
    createAction({
      type: ActionTypes.FETCH_CUSTOM_ROLES_FAILED,
      account,
      error,
    }),
  fetchProjects: (account: Account) => createAction({ type: ActionTypes.FETCH_PROJECTS, account }),
  receiveProjects: (account: Account, response: ProjectResponse) =>
    createAction({
      type: ActionTypes.FETCH_PROJECTS_FULFILLED,
      account,
      response,
    }),
  fetchProjectsFailed: (account: Account, error: RequestError) =>
    createAction({
      type: ActionTypes.FETCH_PROJECTS_FAILED,
      account,
      error,
    }),
  fetchSDKVersions: ({ account, sdkType }: { account: Account; sdkType: SDKType }) =>
    createAction({
      type: ActionTypes.REQUEST_SDK_VERSIONS,
      account,
      sdkType,
    }),
  receiveSDKVersions: (data: any, sdkType: SDKType) =>
    createAction({
      type: ActionTypes.REQUEST_SDK_VERSIONS_FULFILLED,
      data,
      sdkType,
    }),
  fetchSDKVersionsFailed: (error: RequestError, sdkType: SDKType) =>
    createAction({
      type: ActionTypes.REQUEST_SDK_VERSIONS_FAILED,
      sdkType,
      error,
    }),
  updateAccountOwner: (account: Account, promotion: OwnerPromotion) =>
    createAction({
      type: ActionTypes.UPDATE_ACCOUNT_OWNER,
      account,
      promotion,
    }),
  updateAccountOwnerSucceeded: (account: Account, response: MemberResponse) =>
    createAction({
      type: ActionTypes.UPDATE_ACCOUNT_OWNER_FULFILLED,
      account,
      response,
    }),
  updateAccountOwnerFailed: (account: Account, error: RequestError) =>
    createAction({
      type: ActionTypes.UPDATE_ACCOUNT_OWNER_FAILED,
      account,
      error,
    }),
  updateTrialEnd: (account: Account, subscription: Subscription, date: Date) =>
    createAction({
      type: ActionTypes.UPDATE_TRIAL_END,
      account,
      subscription,
      date,
    }),
  updateTrialEndSucceeded: (account: Account, subscription: Subscription, updatedSubscription: Subscription) =>
    createAction({
      type: ActionTypes.UPDATE_TRIAL_END_FULFILLED,
      account,
      subscription,
      updatedSubscription,
    }),
  updateTrialEndFailed: (account: Account, error: RequestError) => {
    return createAction({ type: ActionTypes.UPDATE_TRIAL_END_FAILED, account, error });
  },
  updateGraceEnd: (account: Account, subscription: Subscription, date: Date) =>
    createAction({
      type: ActionTypes.UPDATE_GRACE_END,
      account,
      subscription,
      date,
    }),
  updateGraceEndSucceeded: (account: Account, subscription: Subscription, updatedSubscription: Subscription) =>
    createAction({
      type: ActionTypes.UPDATE_GRACE_END_FULFILLED,
      account,
      subscription,
      updatedSubscription,
    }),
  updateGraceEndFailed: (account: Account, error: RequestError) =>
    createAction({
      type: ActionTypes.UPDATE_GRACE_END_FAILED,
      account,
      error,
    }),
  updateLegacyTrialEnd: (account: Account, subscription: LegacySubscription, date: Date) =>
    createAction({
      type: ActionTypes.UPDATE_LEGACY_TRIAL_END,
      account,
      subscription,
      date,
    }),
  updateLegacyTrialEndSucceeded: (
    account: Account,
    subscription: LegacySubscription,
    updatedSubscription: LegacySubscription,
  ) =>
    createAction({
      type: ActionTypes.UPDATE_LEGACY_TRIAL_END_FULFILLED,
      account,
      subscription,
      updatedSubscription,
    }),
  updateLegacyTrialEndFailed: (account: Account, error: RequestError) =>
    createAction({
      type: ActionTypes.UPDATE_LEGACY_TRIAL_END_FAILED,
      account,
      error,
    }),
  updateLegacyGraceEnd: (account: Account, subscription: LegacySubscription, date: Date) =>
    createAction({
      type: ActionTypes.UPDATE_LEGACY_GRACE_END,
      account,
      subscription,
      date,
    }),
  updateLegacyGraceEndSucceeded: (
    account: Account,
    subscription: LegacySubscription,
    updatedSubscription: LegacySubscription,
  ) =>
    createAction({
      type: ActionTypes.UPDATE_LEGACY_GRACE_END_FULFILLED,
      account,
      subscription,
      updatedSubscription,
    }),
  updateLegacyGraceEndFailed: (account: Account, error: RequestError) =>
    createAction({
      type: ActionTypes.UPDATE_LEGACY_GRACE_END_FAILED,
      account,
      error,
    }),
  updateEnterpriseCampaignDates: (account: Account, subscription: Subscription, date: Date) =>
    createAction({
      type: ActionTypes.UPDATE_ENTERPRISE_CAMPAIGN_DATES,
      account,
      subscription,
      date,
    }),
  updateEnterpriseCampaignDatesSucceeded: (
    account: Account,
    subscription: Subscription,
    updatedSubscription: Subscription,
  ) =>
    createAction({
      type: ActionTypes.UPDATE_ENTERPRISE_CAMPAIGN_DATES_FULFILLED,
      account,
      subscription,
      updatedSubscription,
    }),
  updateEnterpriseCampaignDatesFailed: (account: Account, error: RequestError) => {
    return createAction({ type: ActionTypes.UPDATE_ENTERPRISE_CAMPAIGN_DATES_FAILED, account, error });
  },
  fetchAccountUsage: ({
    account,
    usageIdentifier,
    usageType,
    mauType,
    metric,
    filters,
    settings,
  }: {
    account: Account;
    usageIdentifier: UsageIdentifier;
    usageType: UsageTypes;
    mauType?: UsageTypes;
    metric: UsageOrigin;
    filters: UsageFilters;
    settings?: Settings;
  }) =>
    createAction({
      type: ActionTypes.REQUEST_ACCOUNT_USAGE,
      account,
      usageIdentifier,
      usageType,
      mauType,
      metric,
      filters,
      settings,
    }),
  fetchHighAccountUsage: (usageType: SDKType, dates?: { to: string; from: string }) =>
    createAction({
      type: ActionTypes.REQUEST_HIGH_ACCOUNT_USAGE,
      usageType,
      dates,
    }),
  fetchHighAccountUsageFailed: (error: RequestError) =>
    createAction({
      type: ActionTypes.REQUEST_HIGH_ACCOUNT_USAGE_FAILED,
      error,
    }),
  receiveHighAccountUsage: (data: any) =>
    createAction({
      type: ActionTypes.REQUEST_HIGH_ACCOUNT_USAGE_FULFILLED,
      data,
    }),
  receiveUsage: (data: any, usageIdentifier: UsageIdentifier) =>
    createAction({
      type: ActionTypes.REQUEST_ACCOUNT_USAGE_FULFILLED,
      data,
      usageIdentifier,
    }),
  fetchUsageFailed: (error: RequestError, usageIdentifier: UsageIdentifier) =>
    createAction({
      type: ActionTypes.REQUEST_ACCOUNT_USAGE_FAILED,
      usageIdentifier,
      error,
    }),
  fetchSubscription: (account: Account) =>
    createAction({
      type: ActionTypes.FETCH_SUBSCRIPTION,
      account,
    }),
  receiveSubscription: (account: Account, response: Subscription) =>
    createAction({
      type: ActionTypes.FETCH_SUBSCRIPTION_FULFILLED,
      account,
      response,
    }),
  fetchSubscriptionFailed: (account: Account, error: RequestError) =>
    createAction({
      type: ActionTypes.FETCH_SUBSCRIPTION_FAILED,
      account,
      error,
    }),
  subscribe: (account: Account, subscription: Subscription, plan: Plan) =>
    createAction({
      type: ActionTypes.SUBSCRIBE,
      account,
      subscription,
      plan,
    }),
  subscribeSucceeded: (account: Account, subscription: Subscription, updatedSubscription: Subscription) =>
    createAction({
      type: ActionTypes.SUBSCRIBE_FULFILLED,
      account,
      subscription,
      updatedSubscription,
    }),
  subscribeFailed: (account: Account, plan: Plan, error: RequestError) =>
    createAction({
      type: ActionTypes.SUBSCRIBE_FAILED,
      account,
      plan,
      error,
    }),
  cancelSubscription: (account: Account, endImmediately: boolean) =>
    createAction({
      type: ActionTypes.CANCEL_SUBSCRIPTION,
      account,
      endImmediately,
    }),
  cancelSubscriptionFulfilled: (account: Account) =>
    createAction({
      type: ActionTypes.CANCEL_SUBSCRIPTION_FULFILLED,
      account,
    }),
  cancelSubscriptionFailed: (account: Account, error: RequestError) =>
    createAction({
      type: ActionTypes.CANCEL_SUBSCRIPTION_FAILED,
      account,
      error,
    }),
  clearPendingSubscriptionUpdate: (account: Account, subscription: Subscription) =>
    createAction({
      type: ActionTypes.CLEAR_PENDING_SUBSCRIPTION_UPDATE,
      account,
      subscription,
    }),
  clearPendingSubscriptionUpdateFulfilled: (account: Account, updatedSubscription: Subscription) =>
    createAction({
      type: ActionTypes.CLEAR_PENDING_SUBSCRIPTION_UPDATE_FULFILLED,
      account,
      updatedSubscription,
    }),
  clearPendingSubscriptionUpdateFailed: (account: Account, error: RequestError) =>
    createAction({
      type: ActionTypes.CLEAR_PENDING_SUBSCRIPTION_UPDATE_FAILED,
      account,
      error,
    }),
  updateStripeSubscriptionId: (account: Account, subscription: Subscription, stripeSubscriptionId: string) =>
    createAction({
      type: ActionTypes.UPDATE_STRIPE_SUBSCRIPTION_ID,
      account,
      subscription,
      stripeSubscriptionId,
    }),
  updateStripeSubscriptionIdFulfilled: (account: Account, updatedSubscription: Subscription) =>
    createAction({
      type: ActionTypes.UPDATE_STRIPE_SUBSCRIPTION_ID_FULFILLED,
      account,
      updatedSubscription,
    }),
  updateStripeSubscriptionIdFailed: (account: Account, error: RequestError) =>
    createAction({
      type: ActionTypes.UPDATE_STRIPE_SUBSCRIPTION_ID_FAILED,
      account,
      error,
    }),
  fetchSubscriptionUsage: (account: Account) =>
    createAction({
      type: ActionTypes.FETCH_SUBSCRIPTION_USAGE,
      account,
    }),
  receiveSubscriptionUsage: (account: Account, response: SubscriptionUsage) =>
    createAction({
      type: ActionTypes.FETCH_SUBSCRIPTION_USAGE_FULFILLED,
      account,
      response,
    }),
  fetchSubscriptionUsageFailed: (account: Account, error: RequestError) =>
    createAction({
      type: ActionTypes.FETCH_SUBSCRIPTION_USAGE_FAILED,
      account,
      error,
    }),
  fetchAuditLogEntries: (
    account: Account,
    nextUrl?: string,
    query?: string,
    spec?: string,
    date?: ReadonlyArray<Date | null>,
  ) =>
    createAction({
      type: ActionTypes.FETCH_AUDIT_LOG_ENTRIES,
      account,
      nextUrl,
      query,
      spec,
      date,
    }),
  receiveAuditLogEntries: (account: Account, response: AuditLogEntryResponse) =>
    createAction({
      type: ActionTypes.FETCH_AUDIT_LOG_ENTRIES_FULFILLED,
      account,
      response,
    }),
  fetchAuditLogEntriesFailed: (account: Account, error: RequestError) =>
    createAction({
      type: ActionTypes.FETCH_AUDIT_LOG_ENTRIES_FAILED,
      account,
      error,
    }),
  fetchAuditLogEntryDetail: (account: Account, entryUrl: string) =>
    createAction({
      type: ActionTypes.FETCH_AUDIT_LOG_ENTRY_DETAIL,
      account,
      entryUrl,
    }),
  receiveAuditLogEntryDetail: (account: Account, response: AuditLogEntryDetailResponse) =>
    createAction({
      type: ActionTypes.FETCH_AUDIT_LOG_ENTRY_DETAIL_FULFILLED,
      account,
      response,
    }),
  fetchAuditLogEntryDetailFailed: (account: Account, error: RequestError) =>
    createAction({
      type: ActionTypes.FETCH_AUDIT_LOG_ENTRY_DETAIL_FAILED,
      account,
      error,
    }),
  setMfaDisabled: (account: Account, member: Member) =>
    createAction({
      type: ActionTypes.DISABLE_MFA,
      account,
      member,
    }),
  setMfaDisabledSuccess: (account: Account, response: Member) =>
    createAction({
      type: ActionTypes.DISABLE_MFA_FULFILLED,
      account,
      response,
    }),
  setMfaDisabledFailed: (account: Account, member: Member, error: RequestError) =>
    createAction({
      type: ActionTypes.DISABLE_MFA_FAILED,
      account,
      member,
      error,
    }),
  setSsoDisabled: (account: Account) =>
    createAction({
      type: ActionTypes.SET_SSO_DISABLED,
      account,
    }),
  setSsoDisabledSuccess: (account: Account, response: SamlConfig) =>
    createAction({
      type: ActionTypes.SET_SSO_DISABLED_FULFILLED,
      account,
      response,
    }),
  setSsoDisabledFailed: (account: Account, error: RequestError) =>
    createAction({
      type: ActionTypes.SET_SSO_DISABLED_FAILED,
      account,
      error,
    }),
  searchMembers: (account: Account, query: string) =>
    createAction({
      type: ActionTypes.SEARCH_MEMBERS,
      account,
      query,
    }),

  searchMembersFulfilled: (account: Account, query: string, response: MemberResponse) =>
    createAction({
      type: ActionTypes.SEARCH_MEMBERS_FULFILLED,
      account,
      query,
      response,
    }),

  searchMembersFailed: (account: Account, error: RequestError) =>
    createAction({
      type: ActionTypes.SEARCH_MEMBERS_FAILED,
      account,
      error,
    }),
};

export type Actions = ActionsUnion<typeof Actions>;
