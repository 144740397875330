import { Action, combineReducers } from 'redux';
import {
  Metadata,
  RequestByKeyState,
  RequestError,
  requestReducer,
  requestReducerByKey,
  RequestState,
  Series,
} from '../shared';
import { Actions, ActionTypes } from './actions';
import {
  Account,
  AuditLogEntries,
  AuditLogEntry,
  CustomRole,
  LegacySubscription,
  MAX_RECENTLY_VIEWED_ACCOUNTS,
  Member,
  MemberResponse,
  MemberSessions,
  MembersPaginationData,
  Project,
  SavedAccount,
  SDKType,
  Subscription,
  SubscriptionUsage,
  UsageIdentifier,
} from './model';
import { getAccountsFromLocalStorage, localStorageKeys } from './storageUtils';

type IFavoriteAccountState = SavedAccount[];

const manageFavoritedAccounts = (state: IFavoriteAccountState = [], action: Actions) => {
  const favorited: SavedAccount[] = getAccountsFromLocalStorage(localStorageKeys.FAVORITED);

  if (action.type === ActionTypes.ADD_FAVORITED_ACCOUNT) {
    const favoritedAccounts: SavedAccount[] = [action.favoritedAccount, ...favorited];
    localStorage.setItem(localStorageKeys.FAVORITED, JSON.stringify(favoritedAccounts));
    return favoritedAccounts;
  }

  if (action.type === ActionTypes.REMOVE_FAVORITED_ACCOUNT) {
    const favoritedAccounts: SavedAccount[] = favorited.filter(favorite => favorite.id !== action.favoritedAccount.id);
    localStorage.setItem(localStorageKeys.FAVORITED, JSON.stringify(favoritedAccounts));
    return favoritedAccounts;
  }

  if (action.type === ActionTypes.GET_FAVORITED_ACCOUNT) {
    return favorited;
  }
  return state;
};

interface IEntitiesState {
  [id: string]: Account;
}

type IRecentlyViewedAccountsState = SavedAccount[];

const addRecentlyViewedAccounts = (state: IRecentlyViewedAccountsState = [], action: Actions) => {
  if (action.type === ActionTypes.ADD_ACCOUNT_TO_RECENTLY_VIEWED) {
    const recentlyViewed: SavedAccount[] = getAccountsFromLocalStorage(localStorageKeys.RECENTLY_VIEWED);
    // filter out account to be added from the existing list in the event it's already in recent
    const filterCurrentFromRecent: SavedAccount[] = recentlyViewed.filter(
      recent => recent.id !== action.recentlyViewedAccount.id,
    );
    const recentlyViewedAccounts: SavedAccount[] = [action.recentlyViewedAccount, ...filterCurrentFromRecent].slice(
      0,
      MAX_RECENTLY_VIEWED_ACCOUNTS,
    );
    localStorage.setItem(localStorageKeys.RECENTLY_VIEWED, JSON.stringify(recentlyViewedAccounts));
    return recentlyViewedAccounts;
  }
  return state;
};

const entities = (state: IEntitiesState = {}, action: Actions) => {
  if (action.type === ActionTypes.FETCH_ACCOUNTS_FULFILLED) {
    return action.response.entities.accounts || {};
  }

  if (action.type === ActionTypes.FETCH_ACCOUNT_FULFILLED) {
    return {
      ...state,
      ...action.response.entities.accounts,
    };
  }

  if (action.type === ActionTypes.RECEIVE_ACCOUNT_RESULTS) {
    const accountsReturned = action.response.entities.accounts;

    const newState = { ...state };

    if (accountsReturned) {
      for (const [key, value] of Object.entries(accountsReturned)) {
        if (state[key]) {
          newState[key] = Object.assign({}, state[key], value);
        } else {
          newState[key] = value;
        }
      }
    }

    return {
      ...newState,
    };
  }

  if (action.type === ActionTypes.SET_SSO_DISABLED_FULFILLED) {
    return {
      ...state,
      [action.account._id]: {
        ...action.account,
        samlConfig: action.response,
      },
    };
  }

  return state;
};

interface IMembersPaginationDataState {
  [id: string]: MembersPaginationData;
}

const membersPaginationData = (state: IMembersPaginationDataState = {}, action: Actions) => {
  if (action.type === ActionTypes.FETCH_MEMBERS_PAGINATION_DATA_FULFILLED) {
    const { result } = action.response;
    return {
      ...state,
      [action.account._id]: {
        links: result._links,
        totalCount: result.totalCount,
      },
    };
  }

  return state;
};

interface IMembersByAccountState {
  [id: string]: {
    [id: string]: Member;
  };
}

const membersByAccount = (state: IMembersByAccountState = {}, action: Actions) => {
  if (
    action.type === ActionTypes.FETCH_MEMBERS_FULFILLED ||
    action.type === ActionTypes.UPDATE_ACCOUNT_OWNER_FULFILLED
  ) {
    return {
      ...state,
      [action.account._id]: {
        ...action.response.entities.members,
      },
    };
  }

  if (action.type === ActionTypes.DISABLE_MFA_FULFILLED) {
    const { response } = action;
    return {
      ...state,
      [action.account._id]: {
        ...state[action.account._id],
        [response._id]: response,
      },
    };
  }

  return state;
};

const membersForExportByAccount = (state: IMembersByAccountState = {}, action: Actions) => {
  if (action.type === ActionTypes.FETCH_MEMBERS_FOR_EXPORT_FULFILLED) {
    return {
      ...state,
      [action.account._id]: {
        ...action.response.entities.members,
      },
    };
  }

  return state;
};

interface IMemberSessionsByAccountState {
  [id: string]: {
    [id: string]: MemberSessions;
  };
}

const memberSessionsByAccount = (state: IMemberSessionsByAccountState = {}, action: Actions) => {
  if (action.type === ActionTypes.FETCH_MEMBER_SESSIONS_FULFILLED) {
    return {
      ...state,
      [action.account._id]: {
        ...state[action.account._id],
        ...action.response.entities.memberSessions,
      },
    };
  }

  return state;
};

interface IProjectsByAccountState {
  [id: string]: {
    [id: string]: Project;
  };
}

const projectsByAccount = (state: IProjectsByAccountState = {}, action: Actions) => {
  if (action.type === ActionTypes.FETCH_PROJECTS_FULFILLED) {
    return {
      ...state,
      [action.account._id]: {
        ...state[action.account._id],
        ...action.response.entities.projects,
      },
    };
  }

  return state;
};

interface IAuditLogEntriesByAccountState {
  [id: string]: AuditLogEntries;
  entries?: any;
  isFetching?: any;
}

const auditLogEntriesByAccount = (state: IAuditLogEntriesByAccountState = {}, action: Actions) => {
  switch (action.type) {
    case ActionTypes.FETCH_AUDIT_LOG_ENTRIES:
      if (!action.nextUrl) {
        return {
          ...state,
          [action.account._id]: {
            isFetching: true,
            entries: [],
            items: [],
            _links: {},
          },
        };
      } else {
        return {
          ...state,
          [action.account._id]: {
            ...state[action.account._id],
            isFetching: true,
          },
        };
      }
    case ActionTypes.FETCH_AUDIT_LOG_ENTRIES_FULFILLED:
      const allEntries: AuditLogEntry[] = state[action.account._id].entries;
      const newEntries: AuditLogEntry[] = action.response && action.response.items;
      newEntries.map(entry => allEntries.push(entry));
      return {
        ...state,
        [action.account._id]: {
          ...state[action.account._id],
          isFetching: false,
          ...action.response,
        },
      };
    case ActionTypes.FETCH_AUDIT_LOG_ENTRIES_FAILED:
      return {
        ...state,
        [action.account._id]: {
          ...state[action.account._id],
          isFetching: false,
        },
      };
  }
  return state;
};

interface IScheduledTrialEntriesByAccountState {
  [id: string]: any;
  isFetching?: boolean;
}

const scheduledTrialEntriesByAccount = (state: IScheduledTrialEntriesByAccountState = {}, action: Actions) => {
  switch (action.type) {
    case ActionTypes.GET_SCHEDULED_TRIALS_SUCCESS:
      return {
        ...state,
        [action.flagKey]: action.response.items,
      };
  }
  return state;
};

interface IAuditLogEntryDetailByAccountState {
  [id: string]: any;
  isFetching?: boolean;
}

const initialEntryDetailState = {
  isFetching: false,
};

const auditLogEntryDetailByAccount = (
  state: IAuditLogEntryDetailByAccountState = initialEntryDetailState,
  action: Actions,
) => {
  switch (action.type) {
    case ActionTypes.FETCH_AUDIT_LOG_ENTRY_DETAIL:
      return {
        ...state,
        [action.account._id]: {
          // clears optional fields and handles loading
          ...state[action.account._id],
          parent: undefined,
          token: undefined,
          comment: undefined,
          delta: undefined,
          previousVersion: undefined,
          currentVersion: undefined,
          isFetching: true,
        },
      };
    case ActionTypes.FETCH_AUDIT_LOG_ENTRY_DETAIL_FULFILLED:
      return {
        ...state,
        [action.account._id]: {
          ...state[action.account._id],
          isFetching: false,
          ...action.response,
        },
      };
  }
  return state;
};

interface ICustomRolesByAccountState {
  [id: string]: {
    [id: string]: CustomRole;
  };
}

const customRolesByAccount = (state: ICustomRolesByAccountState = {}, action: Actions) => {
  if (action.type === ActionTypes.FETCH_CUSTOM_ROLES_FULFILLED) {
    return {
      ...state,
      [action.account._id]: {
        ...state[action.account._id],
        ...action.response.entities.customRoles,
      },
    };
  }

  return state;
};

interface ILegacySubscriptionsByAccountState {
  [id: string]: LegacySubscription;
}

interface ISubscriptionsByAccountState {
  [id: string]: Subscription;
}

interface ISubscriptionUsageByAccount {
  [id: string]: SubscriptionUsage;
}

const subscriptionByAccount = (state: ISubscriptionsByAccountState = {}, action: Actions) => {
  switch (action.type) {
    case ActionTypes.FETCH_SUBSCRIPTION_FULFILLED:
      return {
        ...state,
        [action.account._id]: action.response,
      };
    case ActionTypes.SUBSCRIBE_FULFILLED:
    case ActionTypes.UPDATE_TRIAL_END_FULFILLED:
    case ActionTypes.UPDATE_GRACE_END_FULFILLED:
    case ActionTypes.UPDATE_STRIPE_SUBSCRIPTION_ID_FULFILLED:
    case ActionTypes.CLEAR_PENDING_SUBSCRIPTION_UPDATE_FULFILLED:
    case ActionTypes.UPDATE_ENTERPRISE_CAMPAIGN_DATES_FULFILLED:
      return {
        ...state,
        [action.account._id]: action.updatedSubscription,
      };
    default:
      return state;
  }
};

const ownerUpdateRequest = requestReducer([
  ActionTypes.UPDATE_ACCOUNT_OWNER,
  ActionTypes.UPDATE_ACCOUNT_OWNER_FULFILLED,
  ActionTypes.UPDATE_ACCOUNT_OWNER_FAILED,
]);

interface ITrialEndUpdatesByAccountState {
  [id: string]: RequestState;
}

const trialEndUpdateByAccount = requestReducerByKey(
  [ActionTypes.UPDATE_TRIAL_END, ActionTypes.UPDATE_TRIAL_END_FULFILLED, ActionTypes.UPDATE_TRIAL_END_FAILED],
  (action: IRequestByAccountAction) => action.account._id,
);

const graceEndUpdateByAccount = requestReducerByKey(
  [ActionTypes.UPDATE_GRACE_END, ActionTypes.UPDATE_GRACE_END_FULFILLED, ActionTypes.UPDATE_GRACE_END_FAILED],
  (action: IRequestByAccountAction) => action.account._id,
);

const legacySubscriptionByAccount = (state: ILegacySubscriptionsByAccountState = {}, action: Actions) => {
  switch (action.type) {
    case ActionTypes.FETCH_LEGACY_SUBSCRIPTION_FULFILLED:
      return {
        ...state,
        [action.account._id]: action.response,
      };
    case ActionTypes.UPDATE_LEGACY_TRIAL_END_FULFILLED:
    case ActionTypes.UPDATE_LEGACY_GRACE_END_FULFILLED:
      return {
        ...state,
        [action.account._id]: action.updatedSubscription,
      };
    default:
      return state;
  }
};

interface ILegacyTrialEndUpdatesByAccountState {
  [id: string]: RequestState;
}

interface ISearchMembersState {
  isFetching: boolean;
  error: string | object | undefined;
  query: string;
  results: { [id: string]: Member };
  totalCount?: number;
  _links?: {
    self: {
      href: string;
      type: string;
    };
    [key: string]: { href: string; type: string };
  };
}

const initialSearchMembersState: ISearchMembersState = {
  isFetching: false,
  error: undefined,
  query: '',
  results: {},
  totalCount: 0,
  _links: { self: { href: '', type: '' } },
};

const searchMembers = (
  state: ISearchMembersState = initialSearchMembersState,
  action: { type: ActionTypes; response: MemberResponse; error: RequestError; query: string },
): ISearchMembersState => {
  switch (action.type) {
    case ActionTypes.SEARCH_MEMBERS:
      return {
        ...state,
        isFetching: true,
        query: action.query || '',
      };

    case ActionTypes.SEARCH_MEMBERS_FULFILLED:
      return {
        ...state,
        isFetching: false,
        results:
          action.response.items.reduce((acc: Record<string, Member>, member: Member) => {
            acc[member._id] = member;
            return acc;
          }, {}) || {},
        totalCount: action.response.totalCount,
        _links: action.response._links,
      };

    case ActionTypes.SEARCH_MEMBERS_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    default:
      return state;
  }
};
const legacyTrialEndUpdateByAccount = requestReducerByKey(
  [
    ActionTypes.UPDATE_LEGACY_TRIAL_END,
    ActionTypes.UPDATE_LEGACY_TRIAL_END_FULFILLED,
    ActionTypes.UPDATE_LEGACY_TRIAL_END_FAILED,
  ],
  (action: IRequestByAccountAction) => action.account._id,
);

const featureTrialUpdateByAccount = requestReducerByKey(
  [ActionTypes.SET_FEATURE_TRIAL, ActionTypes.SET_FEATURE_TRIAL_SUCCESS],
  (action: IRequestByAccountAction) => action.account._id,
);

const featureExpirationDateUpdateByAccount = requestReducerByKey(
  [ActionTypes.SET_FEATURE_EXPRIATION_DATE, ActionTypes.SET_FEATURE_EXPRIATION_DATE_SUCCESS],
  (action: IRequestByAccountAction) => action.account._id,
);

const featureTrialsByAccount = requestReducerByKey(
  [ActionTypes.GET_SCHEDULED_TRIALS, ActionTypes.GET_SCHEDULED_TRIALS_SUCCESS],
  (action: any) => action.account,
);

const legacyGraceEndUpdateByAccount = requestReducerByKey(
  [
    ActionTypes.UPDATE_LEGACY_GRACE_END,
    ActionTypes.UPDATE_LEGACY_GRACE_END_FULFILLED,
    ActionTypes.UPDATE_LEGACY_GRACE_END_FAILED,
  ],
  (action: IRequestByAccountAction) => action.account._id,
);

const request = requestReducer([
  ActionTypes.FETCH_ACCOUNTS,
  ActionTypes.FETCH_ACCOUNTS_FULFILLED,
  ActionTypes.FETCH_ACCOUNTS_FAILED,
]);

interface IRequestByIdAction extends Action {
  accountId: string;
}

const requestsById = requestReducerByKey(
  [ActionTypes.FETCH_ACCOUNT, ActionTypes.FETCH_ACCOUNT_FULFILLED, ActionTypes.FETCH_ACCOUNT_FAILED],
  (action: IRequestByIdAction) => action.accountId,
);

interface IRequestByAccountAction extends Action {
  account: Account;
}

const subscriptionRequestByAccountId = requestReducerByKey(
  [
    ActionTypes.FETCH_LEGACY_SUBSCRIPTION,
    ActionTypes.FETCH_LEGACY_SUBSCRIPTION_FULFILLED,
    ActionTypes.FETCH_LEGACY_SUBSCRIPTION_FAILED,
  ],
  (action: IRequestByAccountAction) => action.account._id,
);

const membersRequestByAccountId = requestReducerByKey(
  [ActionTypes.FETCH_MEMBERS, ActionTypes.FETCH_MEMBERS_FULFILLED, ActionTypes.FETCH_MEMBERS_FAILED],
  (action: IRequestByAccountAction) => action.account._id,
);

const memberSessionsRequestByAccountId = requestReducerByKey(
  [
    ActionTypes.FETCH_MEMBER_SESSIONS,
    ActionTypes.FETCH_MEMBER_SESSIONS_FULFILLED,
    ActionTypes.FETCH_MEMBER_SESSIONS_FAILED,
  ],
  (action: IRequestByAccountAction) => action.account._id,
);

const projectsRequestByAccountId = requestReducerByKey(
  [ActionTypes.FETCH_PROJECTS, ActionTypes.FETCH_PROJECTS_FULFILLED, ActionTypes.FETCH_PROJECTS_FAILED],
  (action: IRequestByAccountAction) => action.account._id,
);

const auditLogEntriesRequestByAccountId = requestReducerByKey(
  [
    ActionTypes.FETCH_AUDIT_LOG_ENTRIES,
    ActionTypes.FETCH_AUDIT_LOG_ENTRIES_FULFILLED,
    ActionTypes.FETCH_AUDIT_LOG_ENTRIES_FAILED,
  ],
  (action: IRequestByAccountAction) => action.account._id,
);

const auditLogEntryDetailRequestByAccountId = requestReducerByKey(
  [
    ActionTypes.FETCH_AUDIT_LOG_ENTRY_DETAIL,
    ActionTypes.FETCH_AUDIT_LOG_ENTRY_DETAIL_FULFILLED,
    ActionTypes.FETCH_AUDIT_LOG_ENTRY_DETAIL_FAILED,
  ],
  (action: IRequestByAccountAction) => action.account._id,
);

const customRolesRequestByAccountId = requestReducerByKey(
  [ActionTypes.FETCH_CUSTOM_ROLES, ActionTypes.FETCH_CUSTOM_ROLES_FULFILLED, ActionTypes.FETCH_CUSTOM_ROLES_FAILED],
  (action: IRequestByAccountAction) => action.account._id,
);

interface ISearchState {
  isFetching: boolean;
  q: string;
  results: string[];
}

const initialSearchState = {
  isFetching: false,
  q: '',
  results: [],
};

const search = (state: ISearchState = initialSearchState, action: Actions) => {
  switch (action.type) {
    case ActionTypes.SEARCH_ACCOUNTS:
      return {
        ...state,
        isFetching: true,
        q: action.q,
      };
    case ActionTypes.RECEIVE_ACCOUNT_RESULTS:
      return {
        ...state,
        isFetching: false,
        q: action.q,
        results: action.response.result.items,
      };
    case ActionTypes.CLEAR_ACCOUNT_RESULTS:
      return initialSearchState;
    default:
      return state;
  }
};

interface IAccountMetricUsageState {
  metadata: Metadata;
  series: Series;
}

interface IAccountUsageState {
  usageRequest: RequestState;
  data: IAccountMetricUsageState;
}

interface IAccountUsageStates {
  serverConnections: IAccountUsageState;
  serverMAU: IAccountUsageState;
  clientConnections: IAccountUsageState;
  clientMAU: IAccountUsageState;
  experiments: IAccountUsageState;
  dataExport: IAccountUsageState;
}

const initialAccountUsageState = {
  _links: {},
  metadata: [],
  series: [],
};

export const createUsageReducer = (usageIdentifier: UsageIdentifier) => {
  const usageRequest = requestReducer(
    [
      ActionTypes.REQUEST_ACCOUNT_USAGE,
      ActionTypes.REQUEST_ACCOUNT_USAGE_FULFILLED,
      ActionTypes.REQUEST_ACCOUNT_USAGE_FAILED,
    ],
    (action: Actions) => {
      switch (action.type) {
        case ActionTypes.REQUEST_ACCOUNT_USAGE:
        case ActionTypes.REQUEST_ACCOUNT_USAGE_FULFILLED:
        case ActionTypes.REQUEST_ACCOUNT_USAGE_FAILED:
          return action.usageIdentifier === usageIdentifier;
        default:
          return false;
      }
    },
  );
  const data = (state: IAccountMetricUsageState = initialAccountUsageState, action: Actions) => {
    switch (action.type) {
      case ActionTypes.REQUEST_ACCOUNT_USAGE_FULFILLED:
        if (action.usageIdentifier !== usageIdentifier) {
          return state;
        }
        return action.data;
      default:
        return state;
    }
  };

  return combineReducers({ usageRequest, data });
};

export const serverConnectionsUsage = createUsageReducer(UsageIdentifier.SERVER_CONNECTIONS);
export const serverMAUUsage = createUsageReducer(UsageIdentifier.SERVER_MAU);
export const clientConnectionsUsage = createUsageReducer(UsageIdentifier.CLIENT_CONNECTIONS);
export const clientMAUUsage = createUsageReducer(UsageIdentifier.CLIENT_MAU);
export const experimentUsage = createUsageReducer(UsageIdentifier.EXPERIMENTS);
export const dataExportUsage = createUsageReducer(UsageIdentifier.DATA_EXPORT);

export const usage = combineReducers({
  serverConnections: serverConnectionsUsage,
  serverMAU: serverMAUUsage,
  clientConnections: clientConnectionsUsage,
  clientMAU: clientMAUUsage,
  experiments: experimentUsage,
  dataExport: dataExportUsage,
});

interface ISDKUsageStates {
  serverSDK: SDKUsageState;
  clientSDK: SDKUsageState;
}

export type SDKUsageState = Readonly<{
  sdkRequest: RequestState;
  data: any;
}>;

export const createSDKReducer = (sdkType: SDKType) => {
  const sdkRequest = requestReducer(
    [
      ActionTypes.REQUEST_SDK_VERSIONS,
      ActionTypes.REQUEST_SDK_VERSIONS_FULFILLED,
      ActionTypes.REQUEST_SDK_VERSIONS_FAILED,
    ],
    (action: Actions) => {
      switch (action.type) {
        case ActionTypes.REQUEST_SDK_VERSIONS:
        case ActionTypes.REQUEST_SDK_VERSIONS_FULFILLED:
        case ActionTypes.REQUEST_SDK_VERSIONS_FAILED:
          return action.sdkType === sdkType;
        default:
          return false;
      }
    },
  );

  const sdkVersionsList = (state = [], action: Actions) => {
    switch (action.type) {
      case ActionTypes.REQUEST_SDK_VERSIONS_FULFILLED:
        if (action.sdkType !== sdkType) {
          return state;
        }
        return action.data.sdkVersions.sort(
          (a: any, b: any) => (a.sdk !== b.sdk ? a.sdk - b.sdk : a.version - b.version),
        );
      default:
        return state;
    }
  };

  return combineReducers({
    sdkRequest,
    data: sdkVersionsList,
  });
};

export const serverSDK = createSDKReducer(SDKType.SERVER);
export const clientSDK = createSDKReducer(SDKType.CLIENT);

export const sdk = combineReducers({
  serverSDK,
  clientSDK,
});

const subscriptionUsageFetchRequest = requestReducer([
  ActionTypes.FETCH_SUBSCRIPTION_USAGE,
  ActionTypes.FETCH_SUBSCRIPTION_USAGE_FULFILLED,
  ActionTypes.FETCH_SUBSCRIPTION_USAGE_FAILED,
]);

export const subscription = (state: Subscription | null = null, action: Actions) => {
  switch (action.type) {
    case ActionTypes.FETCH_SUBSCRIPTION_FULFILLED:
      return action.response;
    case ActionTypes.SUBSCRIBE_FULFILLED:
      return action.updatedSubscription;
    default:
      return state;
  }
};

const highAccountUsageFetchRequest = requestReducer([
  ActionTypes.REQUEST_HIGH_ACCOUNT_USAGE,
  ActionTypes.REQUEST_HIGH_ACCOUNT_USAGE_FULFILLED,
  ActionTypes.REQUEST_HIGH_ACCOUNT_USAGE_FAILED,
]);

export const highAccountUsage = (state: IAccountMetricUsageState = initialAccountUsageState, action: Actions) => {
  switch (action.type) {
    case ActionTypes.REQUEST_HIGH_ACCOUNT_USAGE_FULFILLED:
      return action.data;
    default:
      return state;
  }
};

export const subscriptionUsage = (state: SubscriptionUsage | null = null, action: Actions) => {
  switch (action.type) {
    case ActionTypes.FETCH_SUBSCRIPTION_USAGE_FULFILLED:
      return action.response;
    default:
      return state;
  }
};

export const subscriptionUsageByAccount = (state: ISubscriptionUsageByAccount = {}, action: Actions) => {
  switch (action.type) {
    case ActionTypes.FETCH_SUBSCRIPTION_USAGE_FULFILLED:
      return {
        ...state,
        [action.account._id]: action.response,
      };
    default:
      return state;
  }
};

export const subscriptionFetchRequest = requestReducer([
  ActionTypes.FETCH_SUBSCRIPTION,
  ActionTypes.FETCH_SUBSCRIPTION_FULFILLED,
  ActionTypes.FETCH_SUBSCRIPTION_FAILED,
]);

export const subscriptionUpdateRequest = requestReducer([
  ActionTypes.SUBSCRIBE,
  ActionTypes.SUBSCRIBE_FULFILLED,
  ActionTypes.SUBSCRIBE_FAILED,
]);

export const setMfaDisabledRequest = requestReducer([
  ActionTypes.DISABLE_MFA,
  ActionTypes.DISABLE_MFA_FULFILLED,
  ActionTypes.DISABLE_MFA_FAILED,
]);

export const setDisableSsoRequest = requestReducer([
  ActionTypes.SET_SSO_DISABLED,
  ActionTypes.SET_SSO_DISABLED_FULFILLED,
  ActionTypes.SET_SSO_DISABLED_FAILED,
]);

export const reducer = combineReducers({
  searchMembers,
  manageFavoritedAccounts,
  entities,
  addRecentlyViewedAccounts,
  membersPaginationData,
  membersByAccount,
  membersForExportByAccount,
  membersRequestByAccountId,
  memberSessionsByAccount,
  memberSessionsRequestByAccountId,
  highAccountUsage,
  projectsByAccount,
  projectsRequestByAccountId,
  auditLogEntriesByAccount,
  auditLogEntriesRequestByAccountId,
  auditLogEntryDetailByAccount,
  auditLogEntryDetailRequestByAccountId,
  customRolesByAccount,
  customRolesRequestByAccountId,
  legacySubscriptionByAccount,
  subscriptionByAccount,
  subscriptionRequestByAccountId,
  legacyTrialEndUpdateByAccount,
  featureTrialUpdateByAccount,
  legacyGraceEndUpdateByAccount,
  graceEndUpdateByAccount,
  trialEndUpdateByAccount,
  ownerUpdateRequest,
  request,
  featureExpirationDateUpdateByAccount,
  requestsById,
  search,
  usage,
  sdk,
  subscription,
  subscriptionFetchRequest,
  subscriptionUsageFetchRequest,
  highAccountUsageFetchRequest,
  subscriptionUpdateRequest,
  subscriptionUsage,
  featureTrialsByAccount,
  scheduledTrialEntriesByAccount,
  subscriptionUsageByAccount,
  setMfaDisabledRequest,
  setDisableSsoRequest,
});

export type UsageState = Readonly<{
  usageRequest: RequestState;
  data: IAccountMetricUsageState;
}>;

export type AccountState = Readonly<{
  searchMembers: ISearchMembersState;
  manageFavoritedAccounts: IFavoriteAccountState;
  entities: IEntitiesState;
  addRecentlyViewedAccounts: IRecentlyViewedAccountsState;
  membersPaginationData: IMembersPaginationDataState;
  membersByAccount: IMembersByAccountState;
  membersForExportByAccount: IMembersByAccountState;
  membersRequestByAccountId: RequestByKeyState;
  memberSessionsByAccount: IMemberSessionsByAccountState;
  memberSessionsRequestByAccountId: RequestByKeyState;
  projectsByAccount: IProjectsByAccountState;
  projectsRequestByAccountId: RequestByKeyState;
  auditLogEntriesByAccount: IAuditLogEntriesByAccountState;
  auditLogEntriesRequestByAccountId: RequestByKeyState;
  auditLogEntryDetailByAccount: IAuditLogEntryDetailByAccountState;
  auditLogEntryDetailRequestByAccountId: RequestByKeyState;
  customRolesByAccount: ICustomRolesByAccountState;
  customRolesRequestByAccountId: RequestByKeyState;
  legacySubscriptionByAccount: ILegacySubscriptionsByAccountState;
  subscriptionByAccount: ISubscriptionsByAccountState;
  subscriptionRequestByAccountId: RequestByKeyState;
  trialEndUpdateByAccount: ITrialEndUpdatesByAccountState;
  scheduledTrialEntriesByAccount: IScheduledTrialEntriesByAccountState;
  legacyTrialEndUpdateByAccount: ILegacyTrialEndUpdatesByAccountState;
  request: RequestState;
  requestsById: RequestByKeyState;
  search: ISearchState;
  usage: IAccountUsageStates;
  sdk: ISDKUsageStates;
  subscription: Subscription | null;
  subscriptionUsage: SubscriptionUsage | null;
  highAccountUsage: IAccountMetricUsageState;
  highAccountUsageFetchRequest: RequestState;
  subscriptionUsageByAccount: ISubscriptionUsageByAccount;
  subscriptionFetchRequest: RequestState;
  subscriptionUsageFetchRequest: RequestState;
  subscriptionUpdateRequest: RequestState;
  ownerUpdateRequest: RequestState;
  setMfaDisabledRequest: RequestState;
  setDisableSsoRequest: RequestState;
}>;
