import { Alert, Button, FormGroup, Intent, MenuItem, NumericInput, Radio, RadioGroup, Switch } from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { Formik, FormikProps } from 'formik';
import { clone, get, set, unset } from 'lodash';
import * as React from 'react';
import { Scopes } from '../../auth';
import { Settings } from '../../settings';
import { createBooleanChangeHandler, DefaultCallout } from '../../shared';
import {
  AccountMetrics,
  BillingInterval,
  isEnterprisePlan,
  isFixedPlan,
  isGuardianPlan,
  isHostBasedPlan,
  isPlanWithSSO,
  isSelfServePlan,
  MetricType,
  Plan,
  PlanType,
  Subscription,
} from '../model';

const PlanTypeSelect = Select.ofType<PlanType>();

export type PlanFormProps = {
  settings: Settings;
  scopes: Scopes;
  subscription: Subscription;
  isSubmitting: boolean;
  onSubmit: (values: Plan) => void;
};

type PlanFormState = Readonly<{
  isConfirmationOpen: boolean;
  enableEventsReceived: boolean;
  enableEventsPublished: boolean;
  enableExperimentationKeys: boolean;
  enableExperimentationMAU: boolean;
}>;

const stepSizes: AccountMetrics = {
  [MetricType.SEATS]: 5,
  [MetricType.SERVER_CONNECTIONS]: 25,
  [MetricType.MONTHLY_ACTIVE_USERS]: 25000,
  [MetricType.EVENTS_RECEIVED]: 1000000,
  [MetricType.EVENTS_PUBLISHED]: 1000000,
  [MetricType.EXPERIMENTATION_KEYS]: 1000000,
  [MetricType.EXPERIMENTATION_MAU]: 1000000,
  [MetricType.SSO]: 1,
  [MetricType.HOSTS]: 1,
};

export class PlanForm extends React.Component<PlanFormProps, PlanFormState> {
  public state = {
    isConfirmationOpen: false,
    enableEventsReceived: !!this.props.subscription._limits[MetricType.EVENTS_RECEIVED],
    enableEventsPublished: !!this.props.subscription._limits[MetricType.EVENTS_PUBLISHED],
    enableExperimentationKeys: !!this.props.subscription._limits[MetricType.EXPERIMENTATION_KEYS],
    enableExperimentationMAU: !!this.props.subscription._limits[MetricType.EXPERIMENTATION_MAU],
  };

  public handleEnableEventsReceivedChange = createBooleanChangeHandler(checked =>
    this.setState(() => ({ enableEventsReceived: checked })),
  );

  public handleEnableEventsPublishedChange = createBooleanChangeHandler(checked =>
    this.setState(() => ({ enableEventsPublished: checked })),
  );

  public handleEnableExperimentationKeysChange = createBooleanChangeHandler(checked =>
    this.setState(() => ({ enableExperimentationKeys: checked })),
  );

  public handleEnableExperimentationMauChange = createBooleanChangeHandler(checked =>
    this.setState(() => ({ enableExperimentationMAU: checked })),
  );

  public render() {
    const { subscription: sub, settings, scopes } = this.props;
    if (
      !scopes.enterprisePlanCreator &&
      sub.planType !== PlanType.STANDARD_TRIAL &&
      sub.planType !== PlanType.ENTERPRISE_TRIAL &&
      sub.planType !== PlanType.STANDARD_TRIAL_2021 &&
      sub.planType !== PlanType.FOUNDATION_TRIAL_2023 &&
      sub.planType !== PlanType.GUARDIAN_TRIAL_2024
    ) {
      return <DefaultCallout>Customer has a subscription and cannot be put on a trial</DefaultCallout>;
    } else {
      return (
        <Formik
          initialValues={{
            planType: sub.planType,
            free: false,
            effectiveStartDate: new Date().getTime(),
            prorationTime: Math.round(new Date().getTime() / 1000),
            limits: {
              [MetricType.SEATS]: sub._limits[MetricType.SEATS] || stepSizes[MetricType.SEATS],
              [MetricType.MONTHLY_ACTIVE_USERS]:
                sub._limits[MetricType.MONTHLY_ACTIVE_USERS] || stepSizes[MetricType.MONTHLY_ACTIVE_USERS],
              [MetricType.EVENTS_RECEIVED]:
                sub._limits[MetricType.EVENTS_RECEIVED] || stepSizes[MetricType.EVENTS_RECEIVED],
              [MetricType.EVENTS_PUBLISHED]:
                sub._limits[MetricType.EVENTS_PUBLISHED] || stepSizes[MetricType.EVENTS_PUBLISHED],
              [MetricType.EXPERIMENTATION_KEYS]:
                sub._limits[MetricType.EXPERIMENTATION_KEYS] || stepSizes[MetricType.EXPERIMENTATION_KEYS],
              [MetricType.EXPERIMENTATION_MAU]:
                sub._limits[MetricType.EXPERIMENTATION_MAU] || stepSizes[MetricType.EXPERIMENTATION_MAU],
              [MetricType.SSO]: sub._limits[MetricType.SSO] || 0,
              [MetricType.HOSTS]: sub._limits[MetricType.HOSTS] || stepSizes[MetricType.HOSTS],
            },
            billingInterval: BillingInterval.MONTHLY_BILLING,
          }}
          validate={(values: Plan) => this.handleValidation(values, settings)}
          onSubmit={() => this.setState({ isConfirmationOpen: true })}
          render={(form: FormikProps<Plan>) => (
            <form onSubmit={form.handleSubmit}>
              <FormGroup
                label="Plan type"
                labelInfo="(required)"
                helperText={this.getFieldHelperText(form, 'planType')}
              >
                <PlanTypeSelect
                  items={
                    scopes.enterprisePlanCreator
                      ? [
                          PlanType.ENTERPRISE,
                          PlanType.GUARDIAN_SEATS_2024,
                          PlanType.STARTER,
                          PlanType.PROFESSIONAL,
                          PlanType.STANDARD_TRIAL,
                          PlanType.ENTERPRISE_TRIAL,
                          PlanType.STANDARD_TRIAL_2021,
                          PlanType.STARTER_2021,
                          PlanType.PROFESSIONAL_2021,
                          PlanType.FOUNDATION_2023,
                          PlanType.ENTERPRISE_2023,
                          PlanType.GUARDIAN_2024,
                          PlanType.FOUNDATION_TRIAL_2023,
                          PlanType.GUARDIAN_TRIAL_2024,
                        ]
                      : [PlanType.STANDARD_TRIAL_2021, PlanType.ENTERPRISE_TRIAL, PlanType.GUARDIAN_TRIAL_2024]
                  }
                  itemRenderer={this.renderPlanItem}
                  onItemSelect={item => form.setFieldValue('planType', item)}
                  filterable={false}
                >
                  <Button text={form.values.planType} rightIcon="double-caret-vertical" />
                </PlanTypeSelect>
              </FormGroup>
              {/* TODO add plan description */}
              {form.values.planType !== PlanType.STANDARD_TRIAL &&
                form.values.planType !== PlanType.ENTERPRISE_TRIAL &&
                form.values.planType !== PlanType.STANDARD_TRIAL_2021 &&
                form.values.planType !== PlanType.FOUNDATION_TRIAL_2023 &&
                form.values.planType !== PlanType.GUARDIAN_TRIAL_2024 && (
                  <FormGroup helperText="Whether this plan should be billed monthly or annually">
                    <RadioGroup
                      name="interval"
                      onChange={event => form.setFieldValue('billingInterval', event.currentTarget.value)}
                      selectedValue={form.values.billingInterval}
                    >
                      <Radio labelElement={<strong>Monthly</strong>} value={BillingInterval.MONTHLY_BILLING} />
                      <Radio labelElement={<strong>Annual</strong>} value={BillingInterval.ANNUAL_BILLING} />
                    </RadioGroup>
                  </FormGroup>
                )}
              {isSelfServePlan(form.values.planType) && (
                <FormGroup helperText="A free plan does not create a corresponding subscription in stripe, so the customer can keep it indefinitely for free.">
                  <Switch
                    id="free"
                    name="free"
                    labelElement={<strong>This is a free plan</strong>}
                    checked={form.values.free}
                    onChange={event => form.setFieldValue('free', event.currentTarget.checked)}
                  />
                </FormGroup>
              )}

              {!isFixedPlan(form.values.planType) && (
                <>
                  {!isHostBasedPlan(form.values.planType) && (
                    <FormGroup
                      label="Number of seats"
                      labelFor="seats"
                      labelInfo="(required)"
                      helperText={this.getFieldHelperText(form, `limits.${MetricType.SEATS}`)}
                      intent={this.getFieldIntent(form, `limits.${MetricType.SEATS}`)}
                    >
                      <NumericInput
                        min={stepSizes[MetricType.SEATS]}
                        stepSize={stepSizes[MetricType.SEATS]}
                        majorStepSize={2 * stepSizes[MetricType.SEATS]}
                        id="seats"
                        name={`limits.${MetricType.SEATS}`}
                        value={this.getDisplayOutput(form.values.limits[MetricType.SEATS])}
                        intent={this.getFieldIntent(form, `limits.${MetricType.SEATS}`)}
                        onBlur={form.handleBlur}
                        onValueChange={value => form.setFieldValue(`limits.${MetricType.SEATS}`, value)}
                        disabled={form.values.limits[MetricType.SEATS] === -1}
                      />
                      <Switch
                        id="unlimited_seats"
                        name="unlimited_seats"
                        checked={form.values.limits[MetricType.SEATS] === -1}
                        labelElement={<strong>Unlimited seats</strong>}
                        onChange={event => {
                          if (event.currentTarget.checked) {
                            form.setFieldValue(`limits.${MetricType.SEATS}`, -1);
                            return;
                          }
                          form.setFieldValue(`limits.${MetricType.SEATS}`, stepSizes[MetricType.SEATS]);
                        }}
                      />
                    </FormGroup>
                  )}
                  {isHostBasedPlan(form.values.planType) && (
                    <FormGroup
                      label="Number of hosts"
                      labelFor="hosts"
                      labelInfo="(required)"
                      helperText={this.getFieldHelperText(form, `limits.${MetricType.HOSTS}`)}
                      intent={this.getFieldIntent(form, `limits.${MetricType.HOSTS}`)}
                    >
                      <NumericInput
                        min={0}
                        stepSize={stepSizes[MetricType.HOSTS]}
                        majorStepSize={2 * stepSizes[MetricType.HOSTS]}
                        id="hosts"
                        name={`limits.${MetricType.HOSTS}`}
                        value={this.getDisplayOutput(form.values.limits[MetricType.HOSTS])}
                        intent={this.getFieldIntent(form, `limits.${MetricType.HOSTS}`)}
                        onBlur={form.handleBlur}
                        onValueChange={value => form.setFieldValue(`limits.${MetricType.HOSTS}`, value)}
                        disabled={form.values.limits[MetricType.HOSTS] === -1}
                      />
                    </FormGroup>
                  )}

                  {!settings.removeServerConnections && (
                    <FormGroup
                      label="Number of server connections"
                      labelFor="server-connections"
                      labelInfo="(required)"
                      helperText={this.getFieldHelperText(form, `limits.${MetricType.SERVER_CONNECTIONS}`)}
                      intent={this.getFieldIntent(form, `limits.${MetricType.SERVER_CONNECTIONS}`)}
                    >
                      <NumericInput
                        min={stepSizes[MetricType.SERVER_CONNECTIONS]}
                        stepSize={stepSizes[MetricType.SERVER_CONNECTIONS]}
                        majorStepSize={2 * stepSizes[MetricType.SERVER_CONNECTIONS]}
                        id="server-connections"
                        name={`limits.${MetricType.SERVER_CONNECTIONS}`}
                        value={form.values.limits[MetricType.SERVER_CONNECTIONS]}
                        intent={this.getFieldIntent(form, `limits.${MetricType.SERVER_CONNECTIONS}`)}
                        onBlur={form.handleBlur}
                        onValueChange={value => form.setFieldValue(`limits.${MetricType.SERVER_CONNECTIONS}`, value)}
                      />
                    </FormGroup>
                  )}

                  <FormGroup
                    label="Number of MAUs"
                    labelFor="monthly-active-users"
                    helperText={this.getFieldHelperText(form, `limits.${MetricType.MONTHLY_ACTIVE_USERS}`)}
                    intent={this.getFieldIntent(form, `limits.${MetricType.MONTHLY_ACTIVE_USERS}`)}
                  >
                    <NumericInput
                      min={stepSizes[MetricType.MONTHLY_ACTIVE_USERS]}
                      stepSize={stepSizes[MetricType.MONTHLY_ACTIVE_USERS]}
                      majorStepSize={2 * stepSizes[MetricType.MONTHLY_ACTIVE_USERS]}
                      id="monthly-active-users"
                      name={`limits.${MetricType.MONTHLY_ACTIVE_USERS}`}
                      value={this.getDisplayOutput(form.values.limits[MetricType.MONTHLY_ACTIVE_USERS])}
                      intent={this.getFieldIntent(form, `limits.${MetricType.MONTHLY_ACTIVE_USERS}`)}
                      onBlur={form.handleBlur}
                      onValueChange={value => form.setFieldValue(`limits.${MetricType.MONTHLY_ACTIVE_USERS}`, value)}
                      disabled={form.values.limits[MetricType.MONTHLY_ACTIVE_USERS] === -1}
                    />
                    <Switch
                      id="unlimited_mau"
                      name="unlimited_mau"
                      checked={form.values.limits[MetricType.MONTHLY_ACTIVE_USERS] === -1}
                      labelElement={<strong>Unlimited MAUs</strong>}
                      onChange={event => {
                        if (event.currentTarget.checked) {
                          form.setFieldValue(`limits.${MetricType.MONTHLY_ACTIVE_USERS}`, -1);
                          return;
                        }
                        form.setFieldValue(
                          `limits.${MetricType.MONTHLY_ACTIVE_USERS}`,
                          stepSizes[MetricType.MONTHLY_ACTIVE_USERS],
                        );
                      }}
                    />
                  </FormGroup>

                  <Switch
                    labelElement={<strong>Include experiment events</strong>}
                    checked={this.state.enableEventsReceived}
                    onChange={this.handleEnableEventsReceivedChange}
                  />
                  {this.state.enableEventsReceived && (
                    <FormGroup
                      label="Number of experiment events"
                      labelFor="events-received"
                      labelInfo="(required)"
                      helperText={this.getFieldHelperText(form, `limits.${MetricType.EVENTS_RECEIVED}`)}
                      intent={this.getFieldIntent(form, `limits.${MetricType.EVENTS_RECEIVED}`)}
                    >
                      <NumericInput
                        min={-1}
                        stepSize={stepSizes[MetricType.EVENTS_RECEIVED]}
                        majorStepSize={2 * stepSizes[MetricType.EVENTS_RECEIVED]}
                        id="events-received"
                        name={`limits.${MetricType.EVENTS_RECEIVED}`}
                        value={form.values.limits[MetricType.EVENTS_RECEIVED]}
                        intent={this.getFieldIntent(form, `limits.${MetricType.EVENTS_RECEIVED}`)}
                        onBlur={form.handleBlur}
                        onValueChange={value => form.setFieldValue(`limits.${MetricType.EVENTS_RECEIVED}`, value)}
                      />
                    </FormGroup>
                  )}

                  <Switch
                    labelElement={<strong>Include data export events</strong>}
                    checked={this.state.enableEventsPublished}
                    onChange={this.handleEnableEventsPublishedChange}
                  />
                  {this.state.enableEventsPublished && (
                    <FormGroup
                      label="Number of data export events"
                      labelFor="events-published"
                      labelInfo="(required)"
                      helperText={this.getFieldHelperText(form, `limits.${MetricType.EVENTS_PUBLISHED}`)}
                      intent={this.getFieldIntent(form, `limits.${MetricType.EVENTS_PUBLISHED}`)}
                    >
                      <NumericInput
                        min={stepSizes[MetricType.EVENTS_PUBLISHED]}
                        stepSize={stepSizes[MetricType.EVENTS_PUBLISHED]}
                        majorStepSize={2 * stepSizes[MetricType.EVENTS_PUBLISHED]}
                        id="events-published"
                        name={`limits.${MetricType.EVENTS_PUBLISHED}`}
                        value={form.values.limits[MetricType.EVENTS_PUBLISHED]}
                        intent={this.getFieldIntent(form, `limits.${MetricType.EVENTS_PUBLISHED}`)}
                        onBlur={form.handleBlur}
                        onValueChange={value => form.setFieldValue(`limits.${MetricType.EVENTS_PUBLISHED}`, value)}
                      />
                    </FormGroup>
                  )}

                  <Switch
                    labelElement={<strong>Include experiment keys</strong>}
                    checked={this.state.enableExperimentationKeys}
                    onChange={this.handleEnableExperimentationKeysChange}
                  />
                  {this.state.enableExperimentationKeys && (
                    <FormGroup
                      label="Number of experiment keys"
                      labelFor="experimentation-keys-received"
                      labelInfo="(required)"
                      helperText={this.getFieldHelperText(form, `limits.${MetricType.EXPERIMENTATION_KEYS}`)}
                      intent={this.getFieldIntent(form, `limits.${MetricType.EXPERIMENTATION_KEYS}`)}
                    >
                      <NumericInput
                        min={stepSizes[MetricType.EXPERIMENTATION_KEYS]}
                        stepSize={stepSizes[MetricType.EXPERIMENTATION_KEYS]}
                        majorStepSize={2 * stepSizes[MetricType.EXPERIMENTATION_KEYS]}
                        id="experimentation-keys"
                        name={`limits.${MetricType.EXPERIMENTATION_KEYS}`}
                        value={form.values.limits[MetricType.EXPERIMENTATION_KEYS]}
                        intent={this.getFieldIntent(form, `limits.${MetricType.EXPERIMENTATION_KEYS}`)}
                        onBlur={form.handleBlur}
                        onValueChange={value => form.setFieldValue(`limits.${MetricType.EXPERIMENTATION_KEYS}`, value)}
                      />
                    </FormGroup>
                  )}
                  <Switch
                    labelElement={<strong>Include Experimentation MAU</strong>}
                    checked={this.state.enableExperimentationMAU}
                    onChange={this.handleEnableExperimentationMauChange}
                  />
                  {this.state.enableExperimentationMAU && (
                    <FormGroup
                      label="Number of Experimentation MAU"
                      labelFor="experimentation-mau"
                      labelInfo="(required)"
                      helperText={this.getFieldHelperText(form, `limits.${MetricType.EXPERIMENTATION_MAU}`)}
                      intent={this.getFieldIntent(form, `limits.${MetricType.EXPERIMENTATION_MAU}`)}
                    >
                      <NumericInput
                        min={stepSizes[MetricType.EXPERIMENTATION_MAU]}
                        stepSize={stepSizes[MetricType.EXPERIMENTATION_MAU]}
                        majorStepSize={2 * stepSizes[MetricType.EXPERIMENTATION_MAU]}
                        id="experimentation-mau"
                        name={`limits.${MetricType.EXPERIMENTATION_MAU}`}
                        value={form.values.limits[MetricType.EXPERIMENTATION_MAU]}
                        intent={this.getFieldIntent(form, `limits.${MetricType.EXPERIMENTATION_MAU}`)}
                        onBlur={form.handleBlur}
                        onValueChange={value => form.setFieldValue(`limits.${MetricType.EXPERIMENTATION_MAU}`, value)}
                      />
                    </FormGroup>
                  )}
                </>
              )}
              {isPlanWithSSO(form.values.planType) && (
                <Switch
                  labelElement={<strong>Enable SSO</strong>}
                  checked={!!form.values.limits[MetricType.SSO]}
                  onChange={event =>
                    form.setFieldValue(`limits.${MetricType.SSO}`, event.currentTarget.checked ? 1 : 0)
                  }
                />
              )}
              {isSelfServePlan(form.values.planType) &&
                !form.values.free && (
                  <FormGroup
                    label="Proration time"
                    labelFor="proration-time"
                    labelInfo="(required)"
                    helperText="When to do the proration, if any is going to happen, in unix time (default is now)"
                  >
                    <NumericInput
                      min={0}
                      id="proration-time"
                      name="prorationTime"
                      value={form.values.prorationTime}
                      onBlur={form.handleBlur}
                      onValueChange={value => form.setFieldValue(`prorationTime`, value)}
                    />
                  </FormGroup>
                )}

              {(isEnterprisePlan(form.values.planType) || isGuardianPlan(form.values.planType) || form.values.free) && (
                <FormGroup
                  label="Effective start date"
                  labelFor="effective-start-date"
                  labelInfo="(required)"
                  helperText="When we should record the plan as having started, in unix millis (default is now)"
                >
                  <NumericInput
                    min={0}
                    id="effective-start-date"
                    name="effectiveStartDate"
                    value={form.values.effectiveStartDate}
                    onBlur={form.handleBlur}
                    onValueChange={value => form.setFieldValue(`effectiveStartDate`, value)}
                  />
                </FormGroup>
              )}
              <FormGroup>
                <Button intent={Intent.PRIMARY} type="submit" disabled={this.props.isSubmitting}>
                  {this.props.isSubmitting ? 'Saving plan…' : 'Save plan'}
                </Button>
                <Alert
                  intent={Intent.DANGER}
                  isOpen={this.state.isConfirmationOpen}
                  cancelButtonText="Cancel"
                  confirmButtonText="Confirm"
                  onConfirm={() => this.handleSubmit(form.values)}
                  onCancel={() => this.setState({ isConfirmationOpen: false })}
                >
                  Are you sure you want to change this plan?
                </Alert>
              </FormGroup>
            </form>
          )}
        />
      );
    }
  }

  public handleValidation = (values: Plan, settings: Settings) => {
    const {
      enableEventsReceived,
      enableEventsPublished,
      enableExperimentationKeys,
      enableExperimentationMAU,
    } = this.state;
    const errors = {};

    if (!isFixedPlan(values.planType)) {
      if (!values.limits[MetricType.SEATS] && !isHostBasedPlan(values.planType)) {
        set(errors, `limits.${MetricType.SEATS}`, 'Number of seats is required');
      }
      if (!values.limits[MetricType.HOSTS] && isHostBasedPlan(values.planType)) {
        set(errors, `limits.${MetricType.HOSTS}`, 'Number of hosts is required');
      }

      if (!settings.removeServerConnections && !values.limits[MetricType.SERVER_CONNECTIONS]) {
        set(errors, `limits.${MetricType.SERVER_CONNECTIONS}`, 'Number of server connections is required');
      }

      if (enableEventsReceived && typeof values.limits[MetricType.EVENTS_RECEIVED] !== 'number') {
        set(errors, `limits.${MetricType.EVENTS_RECEIVED}`, 'Number of events received is required');
      }

      if (enableEventsPublished && typeof values.limits[MetricType.EVENTS_PUBLISHED] !== 'number') {
        set(errors, `limits.${MetricType.EVENTS_PUBLISHED}`, 'Number of events published is required');
      }

      if (enableExperimentationKeys && typeof values.limits[MetricType.EXPERIMENTATION_KEYS] !== 'number') {
        set(errors, `limits.${MetricType.EXPERIMENTATION_KEYS}`, 'Number of Experimentation keys is required');
      }

      if (enableExperimentationMAU && typeof values.limits[MetricType.EXPERIMENTATION_KEYS] !== 'number') {
        set(errors, `limits.${MetricType.EXPERIMENTATION_MAU}`, 'Number of Experimentation MAU is required');
      }

      if (
        enableExperimentationKeys &&
        enableEventsReceived &&
        values.limits[MetricType.EVENTS_RECEIVED] > 0 &&
        values.limits[MetricType.EXPERIMENTATION_KEYS] > 0
      ) {
        set(
          errors,
          `limits.${MetricType.EVENTS_RECEIVED}`,
          'Number of events received and Experimentation keys cannot both be positive',
        );
      }
      if (
        enableExperimentationMAU &&
        enableExperimentationKeys &&
        values.limits[MetricType.EXPERIMENTATION_MAU] > 0 &&
        values.limits[MetricType.EXPERIMENTATION_KEYS] > 0
      ) {
        set(
          errors,
          `limits.${MetricType.EXPERIMENTATION_KEYS}`,
          'Number of Experimentation keys and Experimentation MAU cannot both be positive',
        );
      }
      if (
        enableExperimentationMAU &&
        enableEventsReceived &&
        values.limits[MetricType.EXPERIMENTATION_MAU] > 0 &&
        values.limits[MetricType.EVENTS_RECEIVED] > 0
      ) {
        set(
          errors,
          `limits.${MetricType.EVENTS_RECEIVED}`,
          'Number of events received and Experimentation MAU cannot both be positive',
        );
      }
    }

    if (values.planType === PlanType.LEGACY) {
      set(errors, 'planType', 'You may not switch to or update a legacy plan');
    }

    return errors;
  };

  public handleSubmit = (values: Plan) => {
    const {
      enableEventsReceived,
      enableEventsPublished,
      enableExperimentationKeys,
      enableExperimentationMAU,
    } = this.state;
    const payload = clone(values);

    if (!enableEventsReceived) {
      set(payload, `limits.${MetricType.EVENTS_RECEIVED}`, 0);
    }
    if (!enableEventsPublished) {
      set(payload, `limits.${MetricType.EVENTS_PUBLISHED}`, 0);
    }

    if (!enableExperimentationKeys) {
      set(payload, `limits.${MetricType.EXPERIMENTATION_KEYS}`, 0);
    }

    if (!enableExperimentationMAU) {
      set(payload, `limits.${MetricType.EXPERIMENTATION_MAU}`, 0);
    }

    if (!isEnterprisePlan(payload.planType) && !isGuardianPlan(payload.planType) && !payload.free) {
      unset(payload, 'effectiveStartDate');
    }

    if (!isSelfServePlan(payload.planType)) {
      unset(payload, 'free');
      unset(payload, 'prorationTime');
    }

    if (!isPlanWithSSO(payload.planType)) {
      set(payload, `limits.${MetricType.SSO}`, 0);
    }

    if (payload.free) {
      unset(payload, 'prorationTime');
    }

    if (isHostBasedPlan(payload.planType)) {
      set(payload, `limits.${MetricType.SEATS}`, 0);
    }

    if (!isHostBasedPlan(payload.planType)) {
      set(payload, `limits.${MetricType.HOSTS}`, 0);
    }

    if (isFixedPlan(payload.planType)) {
      unset(payload, 'limits');
    }

    this.props.onSubmit(payload);

    this.setState({ isConfirmationOpen: false });
  };

  private getDisplayOutput(val: number): string {
    if (val === -1) {
      return 'Unlimited';
    }
    return val.toString();
  }

  private renderPlanItem: ItemRenderer<PlanType> = (i, props) => (
    <MenuItem active={props.modifiers.active} onClick={props.handleClick} key={i} text={i} />
  );

  private hasError = (form: FormikProps<Plan>, name: string) =>
    get(form.touched, name, false) && get(form.errors, name, null);

  private getFieldIntent = (form: FormikProps<Plan>, name: string) =>
    this.hasError(form, name) ? Intent.DANGER : Intent.NONE;

  private getFieldHelperText = (form: FormikProps<Plan>, name: string) =>
    this.hasError(form, name) ? get(form.errors, name) : undefined;
}
